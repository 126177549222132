import { FC, ReactNode } from 'react';
import Footer from '../Footer';

interface ILandingPageProps {
  title: string;
  contentBody: ReactNode;
}

const LandingPage: FC<ILandingPageProps> = ({ title, contentBody }) => {
  return (
    <div className='bg-[#ffe5d51a] pt-[5%]'>
      <h1 className='py-[23%] lg:py-[5%] text-2xl lg:text-4xl font-RobotoBold text-center'>
        {title}
      </h1>
      <div className='p-[4%]'>{contentBody}</div>
      <Footer />
    </div>
  );
};

export default LandingPage;
