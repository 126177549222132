import React, { useEffect, useState } from 'react';
import { lifeAtTranzitech } from '../../../../data/careers';
import LifeAtTranzitechCard from './LifeAtTZCard';
import LifeAtTranzitechCardTest from './LifeAtTZCardNew';

type Cardprops = {
  logo?: any;
  hoverColor?: string;
  hoverText: string;
  gradient: string;
  index?: number;
  title: string;
  gridSpan?: number;
  img: string;
};

const LifeAtTranzitechWebViewNew = () => {
  const firstItemInArray = lifeAtTranzitech.slice(0, 1); //get first item
  const remainingInArray = lifeAtTranzitech.slice(5, 9); //remaining items

  const firstArrayToInterChange = lifeAtTranzitech.slice(1, 3); //item position 1 & 2
  const secondArrayToInterChange = lifeAtTranzitech.slice(3, 5); //item position 3 & 4

  const [firstItem, setFirstItem] = useState<Cardprops[]>(
    firstArrayToInterChange
  );

  const [secondItem, setSecondItem] = useState<Cardprops[]>(
    secondArrayToInterChange
  );

  const lifeAtTranzitechList = [
    ...firstItemInArray,
    firstItem[0],
    secondItem[0],
    ...remainingInArray,
  ];

  const swapItems = (arr1: Cardprops, arr2: Cardprops) => {
    return [arr2, arr1];
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const randomFirstItem = swapItems(firstItem[0], firstItem[1]);
      const randomSecondItem = swapItems(secondItem[0], secondItem[1]);
      setFirstItem(randomFirstItem);
      setSecondItem(randomSecondItem);
    }, 7000);

    return () => clearInterval(interval);
  }, [firstItem, secondItem]);

  return (
    <div>
      <div className='grid lg:grid-cols-3  grid-cols-2 p-8 overflow-hidden shrink-0     lg:p-20 w-full  max-w-full  gap-3 lg:justify-center  items-center'>
        {lifeAtTranzitechList.map((item, index) => (
          // <LifeAtTranzitechCard item={item} index={index} />
          <LifeAtTranzitechCardTest key={index} item={item} index={index} />
        ))}
      </div>

      {/* <img
        src="https://avitechvideos.blob.core.windows.net/tranzitech-website-assets/About%20Us/boris-bobrov-B4lknSRZwPM-unsplash.jpg?sv=2022-11-02&ss=bqtf&srt=sco&sp=rwdlacuptfxiy&se=2023-06-22T19:23:56Z&sig=mNqVRjXPlSBv6UitvATY318eJ2hIgj1vYP6L%2FYtAEG4%3D&_=1687433202758"
        alt=""
      /> */}
    </div>
  );
};

export default LifeAtTranzitechWebViewNew;
