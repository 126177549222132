import { FC } from 'react';
import { NavLink } from 'react-router-dom';

import './Breadcrum.css';

type BreadcrumType = {
  title: string;
  href: string;
};

interface IBreadcrumProps {
  items: BreadcrumType[];
}

const Breadcrum: FC<IBreadcrumProps> = ({ items }) => {
  return (
    <ul className='breadcrumb'>
      {items.map((item, index) => (
        <li className='breadcrumb-item text-xs lg:text-sm' key={index}>
          <NavLink to={item.href}>{item.title}</NavLink>
        </li>
      ))}
    </ul>
  );
};

export default Breadcrum;
