import React, { useEffect } from 'react';
// import LifeAtTranzitech from "../../components/Careers/LifeAtTranzitech/LifeAtTranzitech";
import FromOurEmployees from '../../components/Careers/FromOurEmployees/FromOurEmployess';
import GetInTouch from '../../components/GetInTouch/GetInTouch';
import Footer from '../../components/Footer';
import TZTribe from '../../components/Careers/TZTribe/tz-tribe';
import { tZData } from '../../data/dnacard';
import OurCulture from '../../components/Careers/OurCulture';
import CareerTab from '../../components/Careers/carrears-tab';
import CareerHero from '../../components/Careers/HeroCareers/hero-career';
import CarrerCard from '../../components/Careers/careerCard';
import DNACard from '../../components/DNACards/dna-cards';
import LifeAtTranzitech from '../../components/Careers/LifeAtTranzitech/LifeAtTranzitech';
import { scrollToTop } from '../../utils/scrollTop';
// import { lifeAtTranzitech } from "../../data/careers";

const Careers = () => {
  useEffect(() => {
    scrollToTop();
  });
  return (
    <div>
      <div className='overflow-hidden'>
        {' '}
        <CareerHero />
      </div>
      <TZTribe />
      <div className='px-5 sm:px-24 sm:py-10'>
        <h2 className='lg:text-3xl sm:text-2xl leading-9 font-semibold font-Poppins text-left my-5 sm:my-8'>
          Why Tranzitech
        </h2>
        <div className='flex flex-col items-center gap-4 lg:flex-row lg:justify-between mt-6 sm:mt-12'>
          {/* <div className='grid md:grid-cols-3 lg:grid-cols-4 mt-6 sm:mt-12 gap-4 w-full'> */}
          {tZData.map((card, index) => (
            <DNACard key={index} header={card.header} body={card.body} />
          ))}
        </div>
      </div>

      <OurCulture />
      <CareerTab />

      <LifeAtTranzitech />
      <FromOurEmployees />
      <div className=' flex  justify-center mb-8  md:mb-[120px] p-5 '>
        <GetInTouch
          linkName='Job Listings'
          title='Join our Team'
          extraText='hidden'
          linkUrl='https://www.linkedin.com/showcase/vp-peopleos/'
        />
      </div>
      <Footer />
    </div>
  );
};

export default Careers;
