import { useState } from 'react';

import Slider from '../Slider/Slider';
import { Team } from '../../data/homepage-sliders';

const Founders = () => {
  const [activeSlide, setActiveSlide] = useState(0);

  const handlePrevSlide = () => {
    setActiveSlide(activeSlide === 0 ? Team.length - 1 : activeSlide - 1);
  };

  const handleNextSlide = () => {
    setActiveSlide(activeSlide === Team.length - 1 ? 0 : activeSlide + 1);
  };

  return (
    <div className='bg-[#ffe5d51a] h-[100%] pt-[120px] pb-[130px] relative  px-[25px] lg:px-[0px] '>
      <div className='text-center lg:w-[600px] m-auto animate__animated animate__slideInDown'>
        <h1 className=' sm:text-mobileH2 md:text-H2 lg:text-H2 font-PoppinsNormal font-bold'>
          Our Founders
        </h1>
        <p className='mt-[32px] mb-[96px]'>
          Meet our visionary founders, leading Tranzitech and its portfolio
          companies to success and innovation with their expertise in technology
          and business.
        </p>
      </div>
      <div>
        <div className='overflow-hidden w-full rounded-[12px] px-[4%]'>
          <Slider slides={Team} activeSlide={activeSlide} />
        </div>

        <button
          onClick={handlePrevSlide}
          className='absolute top-[44%]  lg:top-[58%]  ml-[4%] bg-[#06001b4d] shadow-dark-shadow px-[10.9px] w-[48px] h-[48px] rounded-[8px]'
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
          >
            <path
              d='M15.0003 3.33C14.8103 3.33 14.6203 3.4 14.4703 3.55L7.95027 10.07C6.89027 11.13 6.89027 12.87 7.95027 13.93L14.4703 20.45C14.7603 20.74 15.2403 20.74 15.5303 20.45C15.8203 20.16 15.8203 19.68 15.5303 19.39L9.01027 12.87C8.53027 12.39 8.53027 11.61 9.01027 11.13L15.5303 4.61C15.8203 4.32 15.8203 3.84 15.5303 3.55C15.3803 3.41 15.1903 3.33 15.0003 3.33Z'
              fill='white'
            />
          </svg>
        </button>
        <button
          onClick={handleNextSlide}
          className='absolute top-[44%]  lg:top-[58%] right-[8%] lg:right-[4%] bg-[#06001b4d] shadow-dark-shadow px-[10.9px] w-[48px] h-[48px] rounded-[8px]'
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
          >
            <path
              d='M9.00021 3.33C9.19021 3.33 9.38021 3.4 9.53021 3.55L16.0502 10.07C17.1102 11.13 17.1102 12.87 16.0502 13.93L9.53021 20.45C9.24021 20.74 8.76021 20.74 8.47021 20.45C8.18021 20.16 8.18021 19.68 8.47021 19.39L14.9902 12.87C15.4702 12.39 15.4702 11.61 14.9902 11.13L8.47021 4.61C8.18021 4.32 8.18021 3.84 8.47021 3.55C8.62021 3.41 8.81021 3.33 9.00021 3.33Z'
              fill='white'
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default Founders;
