import blogPostImg from "../assets/images/blog-card-images.svg";
import avitechLogo from "../assets/icons/avitech-logo.svg";
import tripsLogo from "../assets/icons/trips-logo.svg";
import dealerpayLogo from "../assets/icons/dealerpay-logo.svg";
import tranzitechAvitechLogos from "../assets/icons/tranzitech-avitech-logos.svg";
import TranzitechLogo from "../assets/icons/dark-tranzitech-logo.svg";

export const carouselData = [
  {
    id: 1,
    img: "https://avitechvideos.blob.core.windows.net/tranzitech-website-assets/Home/unsplash_3WMjImq5syU.jpg",
    content: "Leveraging Technology to Innovate for a Better Future",
    buttonText: "Scroll down",
    showBtn: true,
  },
  {
    id: 2,
    img: "https://avitechvideos.blob.core.windows.net/tranzitech-website-assets/Home/unsplash_PhYq704ffdA.jpg",
    content: "Connecting Africa with Revenue Assurance and Embedded Finance",
    buttonText: "Scroll down",
    showBtn: true,
  },
  {
    id: 3,
    img: "https://avitechvideos.blob.core.windows.net/tranzitech-website-assets/Home/shutterstock_1529463056-1.jpg",
    content: "Building a Stronger, More Connected Africa through Technology",
    buttonText: "Scroll down",
    showBtn: true,
  },
];

export const Posts = [
  {
    id: 1,
    title:
      "Trips Partners with Wema Bank to Provide a Seamless Travel Experience 1",
    bannerUrl:
      "https://avitechvideos.blob.core.windows.net/tranzitech-website-assets/Home/MicrosoftTeams-image (30) 1.jpg",
    date: "January 24, 2023",
    readTime: "8 mins read",
    content: `Trips, the innovative app that provides users with access to affordable flights, has partnered with Wema Bank to deliver a seamless travel experience to customers. With this partnership, Wema Bank customers will be able to access the Trips app directly from their bank app and enjoy unbeatable travel deals.<br/><br/>
The Trips app is embedded within the Wema Bank app, allowing customers to easily search for flights, compare prices, and book their tickets, all from within their banking app. This seamless integration means that customers no longer have to switch between apps to search for travel deals or to make payments.<br/><br/>
By partnering with Trips, Wema Bank is providing its customers with a unique value proposition. The app allows users to search for the cheapest flights available, compare prices from multiple airlines, and book tickets directly within the app. This is a significant improvement on the traditional method of searching for flights and making payments through multiple channels.<br/><br/>
One of the key advantages of the Trips app is that it provides users with access to a wide range of travel options. Users can search for flights to different destinations, at different times, and on different airlines. This means that users can easily find the best deal that fits their travel needs and budget.<br/><br/>
In addition, Trips provides users with a range of payment options. Users can pay for their flights using their Wema Bank debit card, or they can choose to pay through other payment channels such as bank transfers, mobile money, or cash.<br/>
The Trips app is not just about booking flights; it also provides users with a range of other travel-related services. Users can search for and book hotels, car rentals, and travel insurance directly within the app. This means that users can plan their entire trip from start to finish within the Trips app.<br/><br/>
The partnership between Trips and Wema Bank is a game-changer for the travel industry. By embedding the Trips app within the Wema Bank app, customers now have access to a one-stop-shop for all their travel needs. This means that customers can save time and money, and enjoy a hassle-free travel experience.<br/><br/>
In conclusion, the partnership between Trips and Wema Bank is a win-win for both companies and customers. Wema Bank customers now have access to a seamless travel experience, while Trips is able to reach a wider customer base. This is an excellent example of how technology can be used to enhance customer experience and deliver value to customers.`,
  },
  {
    id: 2,
    title:
      "Trips Partners with Wema Bank to Provide a Seamless Travel Experience 2",
    bannerUrl: blogPostImg,
    date: "January 24, 2023",
    readTime: "8 mins read",
    content: `Trips, the innovative app that provides users with access to affordable flights, has partnered with Wema Bank to deliver a seamless travel experience to customers. With this partnership, Wema Bank customers will be able to access the Trips app directly from their bank app and enjoy unbeatable travel deals.<br/><br/>
The Trips app is embedded within the Wema Bank app, allowing customers to easily search for flights, compare prices, and book their tickets, all from within their banking app. This seamless integration means that customers no longer have to switch between apps to search for travel deals or to make payments.<br/><br/>
By partnering with Trips, Wema Bank is providing its customers with a unique value proposition. The app allows users to search for the cheapest flights available, compare prices from multiple airlines, and book tickets directly within the app. This is a significant improvement on the traditional method of searching for flights and making payments through multiple channels.<br/><br/>
One of the key advantages of the Trips app is that it provides users with access to a wide range of travel options. Users can search for flights to different destinations, at different times, and on different airlines. This means that users can easily find the best deal that fits their travel needs and budget.<br/><br/>
In addition, Trips provides users with a range of payment options. Users can pay for their flights using their Wema Bank debit card, or they can choose to pay through other payment channels such as bank transfers, mobile money, or cash.<br/>
The Trips app is not just about booking flights; it also provides users with a range of other travel-related services. Users can search for and book hotels, car rentals, and travel insurance directly within the app. This means that users can plan their entire trip from start to finish within the Trips app.<br/><br/>
The partnership between Trips and Wema Bank is a game-changer for the travel industry. By embedding the Trips app within the Wema Bank app, customers now have access to a one-stop-shop for all their travel needs. This means that customers can save time and money, and enjoy a hassle-free travel experience.<br/><br/>
In conclusion, the partnership between Trips and Wema Bank is a win-win for both companies and customers. Wema Bank customers now have access to a seamless travel experience, while Trips is able to reach a wider customer base. This is an excellent example of how technology can be used to enhance customer experience and deliver value to customers.`,
  },
  {
    id: 3,
    title:
      "Trips Partners with Wema Bank to Provide a Seamless Travel Experience 3",
    bannerUrl: blogPostImg,
    date: "January 24, 2023",
    readTime: "8 mins read",
    content: `Trips, the innovative app that provides users with access to affordable flights, has partnered with Wema Bank to deliver a seamless travel experience to customers. With this partnership, Wema Bank customers will be able to access the Trips app directly from their bank app and enjoy unbeatable travel deals.<br/><br/>
The Trips app is embedded within the Wema Bank app, allowing customers to easily search for flights, compare prices, and book their tickets, all from within their banking app. This seamless integration means that customers no longer have to switch between apps to search for travel deals or to make payments.<br/><br/>
By partnering with Trips, Wema Bank is providing its customers with a unique value proposition. The app allows users to search for the cheapest flights available, compare prices from multiple airlines, and book tickets directly within the app. This is a significant improvement on the traditional method of searching for flights and making payments through multiple channels.<br/><br/>
One of the key advantages of the Trips app is that it provides users with access to a wide range of travel options. Users can search for flights to different destinations, at different times, and on different airlines. This means that users can easily find the best deal that fits their travel needs and budget.<br/><br/>
In addition, Trips provides users with a range of payment options. Users can pay for their flights using their Wema Bank debit card, or they can choose to pay through other payment channels such as bank transfers, mobile money, or cash.<br/>
The Trips app is not just about booking flights; it also provides users with a range of other travel-related services. Users can search for and book hotels, car rentals, and travel insurance directly within the app. This means that users can plan their entire trip from start to finish within the Trips app.<br/><br/>
The partnership between Trips and Wema Bank is a game-changer for the travel industry. By embedding the Trips app within the Wema Bank app, customers now have access to a one-stop-shop for all their travel needs. This means that customers can save time and money, and enjoy a hassle-free travel experience.<br/><br/>
In conclusion, the partnership between Trips and Wema Bank is a win-win for both companies and customers. Wema Bank customers now have access to a seamless travel experience, while Trips is able to reach a wider customer base. This is an excellent example of how technology can be used to enhance customer experience and deliver value to customers.`,
  },
  {
    id: 4,
    title:
      "Trips Partners with Wema Bank to Provide a Seamless Travel Experience 4",
    bannerUrl: blogPostImg,
    date: "January 24, 2023",
    readTime: "8 mins read",
    content: `Trips, the innovative app that provides users with access to affordable flights, has partnered with Wema Bank to deliver a seamless travel experience to customers. With this partnership, Wema Bank customers will be able to access the Trips app directly from their bank app and enjoy unbeatable travel deals.<br/><br/>
The Trips app is embedded within the Wema Bank app, allowing customers to easily search for flights, compare prices, and book their tickets, all from within their banking app. This seamless integration means that customers no longer have to switch between apps to search for travel deals or to make payments.<br/><br/>
By partnering with Trips, Wema Bank is providing its customers with a unique value proposition. The app allows users to search for the cheapest flights available, compare prices from multiple airlines, and book tickets directly within the app. This is a significant improvement on the traditional method of searching for flights and making payments through multiple channels.<br/><br/>
One of the key advantages of the Trips app is that it provides users with access to a wide range of travel options. Users can search for flights to different destinations, at different times, and on different airlines. This means that users can easily find the best deal that fits their travel needs and budget.<br/><br/>
In addition, Trips provides users with a range of payment options. Users can pay for their flights using their Wema Bank debit card, or they can choose to pay through other payment channels such as bank transfers, mobile money, or cash.<br/>
The Trips app is not just about booking flights; it also provides users with a range of other travel-related services. Users can search for and book hotels, car rentals, and travel insurance directly within the app. This means that users can plan their entire trip from start to finish within the Trips app.<br/><br/>
The partnership between Trips and Wema Bank is a game-changer for the travel industry. By embedding the Trips app within the Wema Bank app, customers now have access to a one-stop-shop for all their travel needs. This means that customers can save time and money, and enjoy a hassle-free travel experience.<br/><br/>
In conclusion, the partnership between Trips and Wema Bank is a win-win for both companies and customers. Wema Bank customers now have access to a seamless travel experience, while Trips is able to reach a wider customer base. This is an excellent example of how technology can be used to enhance customer experience and deliver value to customers.`,
  },
  {
    id: 5,
    title:
      "Trips Partners with Wema Bank to Provide a Seamless Travel Experience 5",
    bannerUrl: blogPostImg,
    date: "January 24, 2023",
    readTime: "8 mins read",
    content: `Trips, the innovative app that provides users with access to affordable flights, has partnered with Wema Bank to deliver a seamless travel experience to customers. With this partnership, Wema Bank customers will be able to access the Trips app directly from their bank app and enjoy unbeatable travel deals.<br/><br/>
The Trips app is embedded within the Wema Bank app, allowing customers to easily search for flights, compare prices, and book their tickets, all from within their banking app. This seamless integration means that customers no longer have to switch between apps to search for travel deals or to make payments.<br/><br/>
By partnering with Trips, Wema Bank is providing its customers with a unique value proposition. The app allows users to search for the cheapest flights available, compare prices from multiple airlines, and book tickets directly within the app. This is a significant improvement on the traditional method of searching for flights and making payments through multiple channels.<br/><br/>
One of the key advantages of the Trips app is that it provides users with access to a wide range of travel options. Users can search for flights to different destinations, at different times, and on different airlines. This means that users can easily find the best deal that fits their travel needs and budget.<br/><br/>
In addition, Trips provides users with a range of payment options. Users can pay for their flights using their Wema Bank debit card, or they can choose to pay through other payment channels such as bank transfers, mobile money, or cash.<br/>
The Trips app is not just about booking flights; it also provides users with a range of other travel-related services. Users can search for and book hotels, car rentals, and travel insurance directly within the app. This means that users can plan their entire trip from start to finish within the Trips app.<br/><br/>
The partnership between Trips and Wema Bank is a game-changer for the travel industry. By embedding the Trips app within the Wema Bank app, customers now have access to a one-stop-shop for all their travel needs. This means that customers can save time and money, and enjoy a hassle-free travel experience.<br/><br/>
In conclusion, the partnership between Trips and Wema Bank is a win-win for both companies and customers. Wema Bank customers now have access to a seamless travel experience, while Trips is able to reach a wider customer base. This is an excellent example of how technology can be used to enhance customer experience and deliver value to customers.`,
  },
];

export const Logos = [
  {
    id: 1,
    imgUrl: tripsLogo,
  },
  {
    id: 2,
    imgUrl: avitechLogo,
  },
  {
    id: 3,
    imgUrl: dealerpayLogo,
  },
];

export const Team = [
  {
    id: 1,
    name: "Nnamdi Chime",
    title: "Co-founder and CEO",
    imgUrl:
      "https://avitechvideos.blob.core.windows.net/tranzitech-website-assets/Home/Rectangle 32.png",
    companyImgUrl: tranzitechAvitechLogos,
    about:
      "Nnamdi is an innovative leader with over 20 years of experience with deep competencies in Engineering services, Core Banking, Business Administration, and growing businesses.",
  },
  {
    id: 2,
    name: "Bunmi Akinyemiju",
    title: "Co-founder",
    imgUrl:
      "https://avitechvideos.blob.core.windows.net/tranzitech-website-assets/Home/image 54.png",
    companyImgUrl: TranzitechLogo,
    about: `Serial Entrepreneur, Innovator, Investor. Leading VGG Team in Solving African Challenges Through Tech. Recognized by UN, Nigerian Multi-Cultural Council. Board Member of Small Business Association of Michigan. Owner, Enliven Software & US-Based Companies.`,
  },
];
