import { FC, useState } from 'react';

import Carousel from '../../Carousel/Carousel';

import { carouselData } from '../../../data/homepage-sliders';

interface HeroProps {
  scrollDown: () => {} | void;
}

const Hero: FC<HeroProps> = ({ scrollDown }) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  return (
    <div className=''>
      <div className='relative'>
        <div
          className='inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden sm:-top-80'
          aria-hidden='true'
        >
          <Carousel
            carouselData={carouselData}
            onClickBtn={() => setShowModal(true)}
            scrollDown={scrollDown}
          />
        </div>
      </div>
    </div>
  );
};

export default Hero;
