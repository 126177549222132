import moment from "moment";
import { FC } from "react";

interface IPostProps {
  title: string;
  date: string;
  readTime: string;
  postImg: string;
  content: string;
}

const SinglePost: FC<IPostProps> = ({
  title,
  date,
  readTime,
  postImg,
  content,
}) => {
  return (
    <div>
      <div className="border-b border-[#06001b33] mb-[5%]">
        <h1 className="text-xl lg:text-4xl font-PoppinsBold leading-[1.2] ">
          {title}
        </h1>
        <div className="pb-[2%]">
          <span className="text-xs mr-[5px]">{moment(date).format("LL")}</span>
          <span className="text-4xl mt-[-12px]">.</span>
          <span className="text-xs ml-[5px]">{readTime} mins read</span>
        </div>
      </div>

      <div className="mb-[5%]">
        <img src={postImg} alt={title} className="w-[100%]" />
      </div>
      <div
        dangerouslySetInnerHTML={{ __html: content }}
        className="text-left text-justify mb-[10%]"
      />
    </div>
  );
};

export default SinglePost;
