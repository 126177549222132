import { FC } from 'react';
import { Link } from 'react-router-dom';
import rightArrow from '../../../assets/icons/arrow-right-black.svg';
import LearnMoreBTN from '../../UiKits/LearnMoreBTN/LearnMoreBTN';

interface TZTribeProps {}

const TZTribe: FC<TZTribeProps> = ({}) => {
  return (
    <section
      style={{ backgroundColor: '#FFFCFB' }}
      className='text-center relative p-5'
    >
      <h3 className='font-PoppinsBold text-[#06001b99] text-sm md:text-lg  my-5 sm:my-8 animate__animated animate__slideInDown'>
        The TZ Tribe
      </h3>
      <div className=' '>
        <h2 className='font-Poppins font-semibold sm:text-3xl lg:text-H2 lg:w-[50%] sm:w-[70%] w-full  mx-auto  text-xl text-[#06001bcc;]  mb-5  sm:leading-10 lg:leading[120%]   animate__animated animate__slideInDown'>
          Work with us and discover that it's not just a job, it's a journey
          towards personal and professional growth.
        </h2>
      </div>
      <LearnMoreBTN
        href='https://www.linkedin.com/showcase/vp-peopleos/'
        title='Job Listing'
        icon={rightArrow}
        lineColor='#06001b'
        lineColorStyles={'m-auto mb-[36px] '}
        styles={
          'w-[129px] m-auto animate__animated animate__slideInUp my-5 sm:my-8 '
        }
      />
      {/* <p className='text-base text-center inline-block   font-PoppinsBold border-b-2 border-[#06001b] hover:cursor-pointer animate__animated animate__slideInUp'>
        <Link to='https://www.linkedin.com/showcase/vp-peopleos/'>
          Job Listing{' '}
          <img src={rightArrow} alt='learn more ' className='inline' />
        </Link>
      </p> */}
    </section>
  );
};

export default TZTribe;
