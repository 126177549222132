import { FC, useEffect } from 'react';

import LandingPage from '../../components/LandingPage/LandingPage';
import { scrollToTop } from '../../utils/scrollTop';

interface ICookiePolicyProps {}

const CookiePolicy: FC<ICookiePolicyProps> = () => {
  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <LandingPage
      title='Cookie Policy'
      contentBody={
        <div className='text-sm lg:text-base text-[#06001bcc]'>
          <p>
            Please read this cookie policy (“cookie policy”, "policy") carefully
            before using this website (“website”, "service") operated by{' '}
            <strong>Tranzitech Holding</strong> ("us", "we", "our").{' '}
          </p>{' '}
          <br />
          <h1 className='text-base lg:text-2xl font-RobotoBold font-bold'>
            What are Cookies?{' '}
          </h1>
          <br />
          <p>
            Cookies are simple text files that are stored on your computer or
            mobile device by a website’s server. Each cookie is unique to your
            web browser. It will contain some anonymous information such as a
            unique identifier, website’s domain name, and some digits and
            numbers.
          </p>
          <br />
          <h1 className='text-base lg:text-2xl font-RobotoBold font-bold'>
            What type of cookies do we use?
          </h1>
          <br />
          <h1 className='text-base lg:text-2xl font-RobotoBold font-bold'>
            Necessary Cookies
          </h1>
          <br />
          <p>
            Necessary cookies allow us to offer you the best possible experience
            when accessing and navigating through our website and using its
            features. For example, these cookies let us recognize that you have
            created an account and have logged into that account.
          </p>
          <br />
          <h1 className='text-base lg:text-2xl font-RobotoBold'>
            Functionality Cookies
          </h1>{' '}
          <br />
          <p>
            Functionality cookies let us operate the site in accordance with the
            choices you make. For example, we will recognize your username and
            remember how you customized the site during future visits.
          </p>
          <br />
          <h1 className='text-base lg:text-2xl font-RobotoBold'>
            Performance Cookies
          </h1>
          <br />
          <p>
            These are used to gather data on how users use our website and the
            pages visited most often. They monitor only the performance of our
            website as the user interacts with it. All data collected by these
            cookies are anonymous and are solely used to improve the
            functionality of our website.
          </p>
          <br />
          <h1 className='text-base lg:text-2xl font-RobotoBold'>
            How to delete Cookies?
          </h1>{' '}
          <br />
          <p>
            If you want to restrict or block the cookies that are set by our
            website, you can do so through your browser setting. Contact us If
            you have any questions about this policy or our use of cookies,
            please contact us at dpo@avitechng.com.
          </p>
        </div>
      }
    />
  );
};

export default CookiePolicy;
