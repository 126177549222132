import { FC } from 'react';
import { Result } from 'antd';

const Success: FC = () => (
  <div className='py-[30%]'>
    <Result
      status='success'
      title='Message received successfully.'
      subTitle='You will be contacted regarding your inquiry or request'
    />
  </div>
);

export default Success;
