export const PictureMedia = [
  {
    id: 1,
    title: 'Tranzitech’s Team Bonding',
    imgUrl:
      'https://avitechvideos.blob.core.windows.net/tranzitech-website-assets/Media/Rectangle-60.jpg',
  },
  {
    id: 2,
    title: 'Tranzitech’s Dinner',
    imgUrl:
      'https://avitechvideos.blob.core.windows.net/tranzitech-website-assets/Media/Rectangle-61.jpg',
  },
  {
    id: 3,
    title: 'Tranzitech’s Team Bonding',
    imgUrl:
      'https://avitechvideos.blob.core.windows.net/tranzitech-website-assets/Media/Rectangle-60.jpg',
  },
];
