import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import SinglePost from "../../components/SinglePost/SinglePost";
import Breadcrum from "../../components/SinglePost/Breadcrum/Breadcrum";
import Footer from "../../components/Footer";
import ShareLinks from "../../components/SinglePost/ShareLinks/ShareLinks";
import { scrollToTop } from "../../utils/scrollTop";
import { fectPost } from "../../utils/services/posts.service";
import { Spin, message } from "antd";
import { App_URL } from "../../utils/constant";
import { Posts } from "../../data/homepage-sliders";
import { parse } from "path";

const Post: FC = () => {
  const [singlePost, setSinglePost] = useState<any>();
  const [messageApi, contextHolder] = message.useMessage();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    scrollToTop();
  }, []);
  // let baseURL;

  // if (process.env.NODE_ENV === "development") {
  //   baseURL = "localhost:3000" || process.env.REACT_APP_API_URL_DEV;
  // } else {
  //   baseURL = BASE_API_URL;
  // }

  const { title } = useParams();

  const breadcrum = [
    { title: "Home", href: "/" },
    { title: "Media", href: "/media" },
    { title: "News", href: "/media" },
    { title: "Full Article", href: "#" },
  ];

  // const blogTitle = title!.replace(/-/g, " ");

  // const blog = Posts.filter(
  //   (post) => post.title.toLowerCase().trim() === blogTitle.toLowerCase().trim()
  // )[0];

  const fetchSinglePost = async () => {
    setIsLoading(true);
    const payload = {
      id: title?.split("&")[1],
    };
    try {
      // const res = await fectPost(payload);
      // setSinglePost(res.data.data);

      if (payload.id) {
        const post = Posts.filter((item) => item.id.toString() === payload.id);
        setSinglePost(post[0]);
      }
    } catch (error) {
      messageApi.open({
        type: "error",
        content:
          "There was an error while trying to fetch the post. please refresh your browser.",
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    (async () => await fetchSinglePost())();
  }, []);

  if (isLoading) {
    return (
      <div className="h-screen flex justify-center items-center">
        <Spin />
      </div>
    );
  }

  if (!singlePost) {
    return (
      <div className="h-screen flex justify-center items-center">
        <p>There is active no post</p>
      </div>
    );
  }

  return (
    <div className="relative">
      <div className="absolute"> {contextHolder}</div>
      <div className="bg-[#06001b24] px-[26px] lg:px-[60px] mb-[40px] mt-[96px] lg:my-[96px] py-[24px]">
        <Breadcrum items={breadcrum} />
      </div>
      <div className="lg:px-[60px] lg:flex lg:flex-row mb-[30px] lg:mb-[0px]">
        <div className="flex-2 w-[90%] m-auto lg:m-[0px] lg:w-[70%]">
          <SinglePost
            title={singlePost.title}
            postImg={singlePost.bannerUrl}
            date={singlePost.dateCreated}
            content={singlePost.content}
            readTime={singlePost.readTime}
          />
        </div>
        <div className="flex-2 pl-[5%] lg:pl-[15%] lg:pt-[15%] ">
          <p className="text-center hidden lg:block">Share</p>
          <ShareLinks href={`${App_URL}/media/news/${title}`} />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Post;
