import { FC } from 'react';

interface ButtonProps {
  label: string;
  children?: string;
  className: string;
  onClick: () => {};
}

const Button: FC<ButtonProps> = ({ label, children, className, ...rest }) => {
  return (
    <button {...rest} className={`sp-button ${className}`}>
      {label || children}
    </button>
  );
};

export default Button;
