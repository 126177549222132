import React, { useState } from 'react';

interface ILearnMoreBTNProps {
  title: string;
  href: string;
  icon: string;
  lineColor?: string;
  hoverLineColor?: string;
  styles?: {};
  lineColorStyles?: {};
}

const LearnMoreBTN: React.FC<ILearnMoreBTNProps> = ({
  href,
  title,
  icon,
  lineColor,
  hoverLineColor,
  styles,
  lineColorStyles,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseOver = () => {
    setIsHovered(true);
  };

  const handleMouseOut = () => {
    setIsHovered(false);
  };
  return (
    <div className={`${styles} overflow-hidden`}>
      <div
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        className={`text-base flex flex-row justify-center items-center font-PoppinsBold cursor-pointer infinite animate__animated animate__slideInUp`}
      >
        <a href={href}>{title} </a>
        <span
          className={`pl-[4px] ${
            isHovered
              ? 'translate-x-1/4 transition-colors duration-100 ease-in-out'
              : ''
          }`}
        >
          <img src={icon} alt={title} />
        </span>
      </div>
      <div
        className={`mb-[36px] w-[122px] ${lineColorStyles} border-b-2 border-[${lineColor}]  hover:border-[${hoverLineColor}] ${
          isHovered &&
          `border-[${hoverLineColor}] animate__animated animate__faster animate__slideInLeft`
        }  transition-colors duration-100`}
      ></div>
    </div>
  );
};

export default LearnMoreBTN;
