import { FC, useRef } from 'react';
import { message } from 'antd';

import {
  copyToClipboard,
  handleShareEmail,
  handleTwitterShare,
  handleLinkedInShare,
} from '../../../utils/shareHelper';

import linkIcon from '../../../assets/icons/post-link-icon.svg';
import mailIcon from '../../../assets/icons/post-mail-icon.svg';
import twitterIcon from '../../../assets/icons/post-twitter-icon.svg';
import linkedInIcon from '../../../assets/icons/post-linkedin-icon.svg';

interface IShareLinksProps {
  href: string;
}

const ShareLinks: FC<IShareLinksProps> = ({ href }) => {
  const [messageApi, contextHolder] = message.useMessage();

  const postUrl = href.replace(/ /g, '-');

  const hrefRef = useRef<HTMLTextAreaElement>(null);

  const copyLink = () => {
    copyToClipboard(hrefRef);
    messageApi.open({
      type: 'success',
      content: 'Url Copied',
    });
  };

  return (
    <div className='space-x-8 lg:space-y-8 lg:space-x-0 mt-[40px] flex flex-row lg:flex lg:flex-col'>
      <div onClick={copyLink} className='relative'>
        <div className='absolute'> {contextHolder}</div>
        <div className='opacity-0 absolute h-[4px] mt-[-50%] w-[2px] cursor-pointer'>
          <textarea
            value={postUrl}
            ref={hrefRef}
            readOnly
            className='h-[4px] w-[2px] cursor-pointer'
          />
        </div>
        <img src={linkIcon} alt='copy link' className='cursor-pointer' />
      </div>
      <div onClick={() => handleShareEmail(postUrl)}>
        <img src={mailIcon} alt='share via mail' className='cursor-pointer' />
      </div>
      <div onClick={() => handleTwitterShare(postUrl)}>
        <img
          src={twitterIcon}
          alt='share via twitter'
          className='cursor-pointer'
        />
      </div>
      <div onClick={() => handleLinkedInShare(postUrl)}>
        <img
          src={linkedInIcon}
          alt='share via linkedin'
          className='cursor-pointer'
        />
      </div>
    </div>
  );
};

export default ShareLinks;
