import { FC, useState } from 'react';
import { Empty } from 'antd';

import pictureMediaIcon from '../../assets/icons/media-gallery-icon.svg';
import videoMediaIcon from '../../assets/icons/media-video-play-icon.svg';

import PictureMediaTab from './PictureMediaTab';
import VideoMediaTab from './VideoMediaTab';

interface IMediaProps {}

const MediaLibrary: FC<IMediaProps> = ({}) => {
  const [activeTab, setActiveTab] = useState<string>('picture');

  const renderTabScreen = () => {
    switch (activeTab) {
      case 'picture':
        return <PictureMediaTab />;
      case 'video':
        return <VideoMediaTab />;
      default:
        return <Empty />;
    }
  };

  return (
    <div>
      <h1 className='text-4xl lg:py-[3%] py-[9%] font-PoppinsBold'>
        Media Library
      </h1>
      <div className='flex flex-row space-x-9'>
        <div
          className={`${
            activeTab === 'picture' &&
            'bg-white shadow-md shadow-dark-shadow rounded-[12px] cursor-pointer hover:cursor-pointer border-b-8 border-[#000000] '
          } p-[26px] `}
          onClick={() => setActiveTab('picture')}
        >
          <img src={pictureMediaIcon} alt='media icon' className='w-[50px]' />
          <small>Images</small>
        </div>
        <div
          className={`${
            activeTab === 'video' &&
            'bg-white shadow-md shadow-dark-shadow rounded-[12px] cursor-pointer hover:cursor-pointer border-b-8 border-[#000000]'
          } p-[26px] `}
          onClick={() => setActiveTab('video')}
        >
          <img src={videoMediaIcon} alt='media icon' className='w-[50px]' />
          <small>Videos</small>
        </div>
      </div>
      <div>{renderTabScreen()}</div>
    </div>
  );
};

export default MediaLibrary;
