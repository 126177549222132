import tranzitechAvitechLogos from '../assets/icons/tranzitech-avitech-logos.svg';
import Damilola from '../assets/images/careers/Employee.png';

export const lifeAtTranzitech = [
  {
    hoverColor: 'bg-[#5C00DF]',
    title: 'Internal Transfer Program',
    gradient: '92, 0, 223, 0.25',
    gridSpan: 2,
    hoverText:
      'Looking to take your career to the next level? Our internal transfer program allows employees to explore new roles, develop new skills, and expand their professional networks without having to leave the company. With a range of job opportunities available to all employees, you can take charge of your career and find the right fit for you.',
    // img: "https://avitechvideos.blob.core.windows.net/tranzitech-website-assets/Careers/shutterstock_1744993268%201.jpg?sv=2022-11-02&ss=bqtf&srt=sco&sp=rwdlacuptfxiy&se=2023-06-22T19:33:53Z&sig=3Yj6ht71oNUDbtL5Ie6GkHcuqfeIzmunYVbIA2DQDZ4%3D&_=1687434377325",
    img: 'https://avitechvideos.blob.core.windows.net/tranzitech-website-assets/Careers/shutterstock_2241139213-1.jpg',
  },

  {
    hoverColor: 'bg-[#61558B]',
    title: 'TownHall',
    gridSpan: 1,
    hoverText:
      "Tranzitech's monthly townhall is a great chance for regional colleagues to bond, collaborate, and socialize in a relaxed setting. From work discussions to team-building activities",
    gradient: '97, 85, 139, 0.25',
    // img: "https://avitechvideos.blob.core.windows.net/tranzitech-website-assets/Careers/shutterstock_1929731783%201.jpg?sv=2022-11-02&ss=bqtf&srt=sco&sp=rwdlacuptfxiy&se=2023-06-22T19:33:53Z&sig=3Yj6ht71oNUDbtL5Ie6GkHcuqfeIzmunYVbIA2DQDZ4%3D&_=1687434444026",
    img: 'https://avitechvideos.blob.core.windows.net/tranzitech-website-assets/Careers/shutterstock_2031288695-1.jpg',
  },
  {
    hoverColor: 'bg-[#477C51]',
    title: 'Team Bonding',
    gradient: '71, 124, 81, 0.25',
    gridSpan: 1,
    hoverText:
      "Tranzitech recognizes that strong collaboration among departments is vital for achieving its business objectives. To foster this spirit of teamwork, the company regularly organizes interdepartmental team bonding activities. These activities not only bring employees together but also serve as a reward for their commitment to the company's success.",
    // img: "https://avitechvideos.blob.core.windows.net/tranzitech-website-assets/Careers/shutterstock_2031288695%201.jpg?sv=2022-11-02&ss=bqtf&srt=sco&sp=rwdlacuptfxiy&se=2023-06-22T19:33:53Z&sig=3Yj6ht71oNUDbtL5Ie6GkHcuqfeIzmunYVbIA2DQDZ4%3D&_=1687434494182",
    img: 'https://avitechvideos.blob.core.windows.net/tranzitech-website-assets/Careers/Rectangle-60.jpg',
  },
  {
    hoverColor: 'bg-[#477C51]',
    title: 'Insurance Plan',
    gradient: '71, 124, 81, 0.25',
    gridSpan: 1,
    hoverText:
      "We value our employees' financial well-being and peace of mind. That's why we offer tailored insurance plans to cover life, health, disability, and auto. Invest in your future and be prepared for unexpected events with our comprehensive coverage.",
    // img: "https://avitechvideos.blob.core.windows.net/tranzitech-website-assets/Careers/shutterstock_2104497341%201.jpg?sv=2022-11-02&ss=bqtf&srt=sco&sp=rwdlacuptfxiy&se=2023-06-22T19:33:53Z&sig=3Yj6ht71oNUDbtL5Ie6GkHcuqfeIzmunYVbIA2DQDZ4%3D&_=1687434609603",
    img: 'https://avitechvideos.blob.core.windows.net/tranzitech-website-assets/Careers/shutterstock_2267293179-1.jpg',
  },
  {
    hoverColor: 'bg-[#FE7F2D]',
    title: 'Great Vacations',
    gradient: '254, 127, 45, 0.25',
    gridSpan: 1,
    hoverText:
      "We believe in the importance of taking time off to recharge, relax, and explore new places. That's why we offer great vacation packages that allow you to explore new destinations, create lasting memories, and come back to work feeling refreshed and energized.",
    // img: "https://avitechvideos.blob.core.windows.net/tranzitech-website-assets/Careers/shutterstock_1272443692%201.jpg?sv=2022-11-02&ss=bqtf&srt=sco&sp=rwdlacuptfxiy&se=2023-06-22T19:33:53Z&sig=3Yj6ht71oNUDbtL5Ie6GkHcuqfeIzmunYVbIA2DQDZ4%3D&_=1687434646641",
    img: 'https://avitechvideos.blob.core.windows.net/tranzitech-website-assets/Careers/shutterstock_2146978083-1.jpg',
  },

  {
    hoverColor: 'bg-[#FE7F2D]',
    title: 'Flexible Work Hours',
    gridSpan: 1,
    hoverText:
      "We prioritize work-life balance at Tranzitech with flexible work hours. Choose a schedule that fits your lifestyle and personal commitments, whether early mornings, late nights, or weekends. Join us to prioritize your personal needs and achieve a better work-life balance.",
    gradient: '254, 127, 45, 0.25',
    // img: "https://avitechvideos.blob.core.windows.net/tranzitech-website-assets/Careers/shutterstock_2146978083%201.jpg?sv=2022-11-02&ss=bqtf&srt=sco&sp=rwdlacuptfxiy&se=2023-06-22T19:33:53Z&sig=3Yj6ht71oNUDbtL5Ie6GkHcuqfeIzmunYVbIA2DQDZ4%3D&_=1687434758118",
    img: 'https://avitechvideos.blob.core.windows.net/tranzitech-website-assets/Careers/shutterstock_1937056084.jpg',
  },
  {
    hoverColor: 'bg-[#772AE4]',
    title: 'Innovation Gamification',
    gradient: '92, 0, 223, 0.25',
    gridSpan: 1,
    hoverText:
      "Tranzitech's yearly innovation challenge sparks employee collaboration, creativity, and skill-building through healthy competition and rewards. The winning idea earns a cash prize and potential development as a subsidiary.",
    // img: "https://avitechvideos.blob.core.windows.net/tranzitech-website-assets/Careers/shutterstock_2104497341%201.jpg?sv=2022-11-02&ss=bqtf&srt=sco&sp=rwdlacuptfxiy&se=2023-06-22T19:33:53Z&sig=3Yj6ht71oNUDbtL5Ie6GkHcuqfeIzmunYVbIA2DQDZ4%3D&_=1687434722427",
    img: 'https://avitechvideos.blob.core.windows.net/tranzitech-website-assets/Careers/shutterstock_641072062-1.jpg',
  },

  {
    hoverColor: 'bg-[#FE7F2D]',
    title: 'Tranzitech Academy',
    gridSpan: 1,
    hoverText:
      "Tranzitech offers an employee development platform that includes an online academy featuring a variety of relevant skill-building courses. While work is a priority, Tranzitech also emphasizes the importance of upskilling and personal growth for its employees.",
    gradient: '254, 127, 45, 0.25',
    // img: "https://avitechvideos.blob.core.windows.net/tranzitech-website-assets/Careers/shutterstock_2151104711%201.jpg?sv=2022-11-02&ss=bqtf&srt=sco&sp=rwdlacuptfxiy&se=2023-06-22T19:33:53Z&sig=3Yj6ht71oNUDbtL5Ie6GkHcuqfeIzmunYVbIA2DQDZ4%3D&_=1687434778463",
    img: 'https://avitechvideos.blob.core.windows.net/tranzitech-website-assets/Careers/shutterstock_1744993268.jpg',
  },

  {
    hoverColor: 'bg-[#61558B]',
    title: 'Tranzitech’s Party',
    gridSpan: 2,
    hoverText:
      "At Tranzitech, we believe in celebrating our achievements, having fun, and strengthening our bonds as a team. That's why we host an end-of-the-year party that brings all employees together to celebrate our successes, reflect on the year gone by, and look forward to new beginnings. Our end-of-the-year party provides an excellent opportunity to connect with colleagues, unwind, and enjoy a night of great food, music, and entertainment.",
    gradient: '97, 85, 139, 0.25',
    // img: "https://avitechvideos.blob.core.windows.net/tranzitech-website-assets/Careers/shutterstock_2241139213%201.jpg?sv=2022-11-02&ss=bqtf&srt=sco&sp=rwdlacuptfxiy&se=2023-06-22T19:33:53Z&sig=3Yj6ht71oNUDbtL5Ie6GkHcuqfeIzmunYVbIA2DQDZ4%3D&_=1687434806068",
    img: 'https://avitechvideos.blob.core.windows.net/tranzitech-website-assets/Careers/Rectangle-61.jpg',
  }
];
export const lifeAtTranzitechMobile = [
   {
    hoverColor: 'bg-[#5C00DF]',
    title: 'Internal Transfer Program',
    gradient: '92, 0, 223, 0.25',
    gridSpan: 2,
    hoverText:
      'Looking to take your career to the next level? Our internal transfer program allows employees to explore new roles, develop new skills, and expand their professional networks without having to leave the company. With a range of job opportunities available to all employees, you can take charge of your career and find the right fit for you.',
    // img: "https://avitechvideos.blob.core.windows.net/tranzitech-website-assets/Careers/shutterstock_1744993268%201.jpg?sv=2022-11-02&ss=bqtf&srt=sco&sp=rwdlacuptfxiy&se=2023-06-22T19:33:53Z&sig=3Yj6ht71oNUDbtL5Ie6GkHcuqfeIzmunYVbIA2DQDZ4%3D&_=1687434377325",
    img: 'https://avitechvideos.blob.core.windows.net/tranzitech-website-assets/Careers/shutterstock_2241139213-1.jpg',
  },

  {
    hoverColor: 'bg-[#61558B]',
    title: 'TownHall',
    gridSpan: 1,
    hoverText:
      "Tranzitech's monthly townhall is a great chance for regional colleagues to bond, collaborate, and socialize in a relaxed setting. From work discussions to team-building activities",
    gradient: '97, 85, 139, 0.25',
    // img: "https://avitechvideos.blob.core.windows.net/tranzitech-website-assets/Careers/shutterstock_1929731783%201.jpg?sv=2022-11-02&ss=bqtf&srt=sco&sp=rwdlacuptfxiy&se=2023-06-22T19:33:53Z&sig=3Yj6ht71oNUDbtL5Ie6GkHcuqfeIzmunYVbIA2DQDZ4%3D&_=1687434444026",
    img: 'https://avitechvideos.blob.core.windows.net/tranzitech-website-assets/Careers/shutterstock_2031288695-1.jpg',
  },
  {
    hoverColor: 'bg-[#477C51]',
    title: 'Team Bonding',
    gradient: '71, 124, 81, 0.25',
    gridSpan: 1,
    hoverText:
      "Tranzitech recognizes that strong collaboration among departments is vital for achieving its business objectives. To foster this spirit of teamwork, the company regularly organizes interdepartmental team bonding activities. These activities not only bring employees together but also serve as a reward for their commitment to the company's success.",
    // img: "https://avitechvideos.blob.core.windows.net/tranzitech-website-assets/Careers/shutterstock_2031288695%201.jpg?sv=2022-11-02&ss=bqtf&srt=sco&sp=rwdlacuptfxiy&se=2023-06-22T19:33:53Z&sig=3Yj6ht71oNUDbtL5Ie6GkHcuqfeIzmunYVbIA2DQDZ4%3D&_=1687434494182",
    img: 'https://avitechvideos.blob.core.windows.net/tranzitech-website-assets/Careers/Rectangle-60.jpg',
  },
  {
    hoverColor: 'bg-[#477C51]',
    title: 'Insurance Plan',
    gradient: '71, 124, 81, 0.25',
    gridSpan: 1,
    hoverText:
      "We value our employees' financial well-being and peace of mind. That's why we offer tailored insurance plans to cover life, health, disability, and auto. Invest in your future and be prepared for unexpected events with our comprehensive coverage.",
    // img: "https://avitechvideos.blob.core.windows.net/tranzitech-website-assets/Careers/shutterstock_2104497341%201.jpg?sv=2022-11-02&ss=bqtf&srt=sco&sp=rwdlacuptfxiy&se=2023-06-22T19:33:53Z&sig=3Yj6ht71oNUDbtL5Ie6GkHcuqfeIzmunYVbIA2DQDZ4%3D&_=1687434609603",
    img: 'https://avitechvideos.blob.core.windows.net/tranzitech-website-assets/Careers/shutterstock_2267293179-1.jpg',
  },
  {
    hoverColor: 'bg-[#FE7F2D]',
    title: 'Great Vacations',
    gradient: '254, 127, 45, 0.25',
    gridSpan: 1,
    hoverText:
      "We believe in the importance of taking time off to recharge, relax, and explore new places. That's why we offer great vacation packages that allow you to explore new destinations, create lasting memories, and come back to work feeling refreshed and energized.",
    // img: "https://avitechvideos.blob.core.windows.net/tranzitech-website-assets/Careers/shutterstock_1272443692%201.jpg?sv=2022-11-02&ss=bqtf&srt=sco&sp=rwdlacuptfxiy&se=2023-06-22T19:33:53Z&sig=3Yj6ht71oNUDbtL5Ie6GkHcuqfeIzmunYVbIA2DQDZ4%3D&_=1687434646641",
    img: 'https://avitechvideos.blob.core.windows.net/tranzitech-website-assets/Careers/shutterstock_2146978083-1.jpg',
  },

  {
    hoverColor: 'bg-[#FE7F2D]',
    title: 'Flexible Work Hours',
    gridSpan: 1,
    hoverText:
      "We prioritize work-life balance at Tranzitech with flexible work hours. Choose a schedule that fits your lifestyle and personal commitments, whether early mornings, late nights, or weekends. Join us to prioritize your personal needs and achieve a better work-life balance.",
    gradient: '254, 127, 45, 0.25',
    // img: "https://avitechvideos.blob.core.windows.net/tranzitech-website-assets/Careers/shutterstock_2146978083%201.jpg?sv=2022-11-02&ss=bqtf&srt=sco&sp=rwdlacuptfxiy&se=2023-06-22T19:33:53Z&sig=3Yj6ht71oNUDbtL5Ie6GkHcuqfeIzmunYVbIA2DQDZ4%3D&_=1687434758118",
    img: 'https://avitechvideos.blob.core.windows.net/tranzitech-website-assets/Careers/shutterstock_1937056084.jpg',
  },
  {
    hoverColor: 'bg-[#772AE4]',
    title: 'Innovation Gamification',
    gradient: '92, 0, 223, 0.25',
    gridSpan: 1,
    hoverText:
      "Tranzitech's yearly innovation challenge sparks employee collaboration, creativity, and skill-building through healthy competition and rewards. The winning idea earns a cash prize and potential development as a subsidiary.",
    // img: "https://avitechvideos.blob.core.windows.net/tranzitech-website-assets/Careers/shutterstock_2104497341%201.jpg?sv=2022-11-02&ss=bqtf&srt=sco&sp=rwdlacuptfxiy&se=2023-06-22T19:33:53Z&sig=3Yj6ht71oNUDbtL5Ie6GkHcuqfeIzmunYVbIA2DQDZ4%3D&_=1687434722427",
    img: 'https://avitechvideos.blob.core.windows.net/tranzitech-website-assets/Careers/shutterstock_641072062-1.jpg',
  },

  {
    hoverColor: 'bg-[#FE7F2D]',
    title: 'Tranzitech Academy',
    gridSpan: 1,
    hoverText:
      "Tranzitech offers an employee development platform that includes an online academy featuring a variety of relevant skill-building courses. While work is a priority, Tranzitech also emphasizes the importance of upskilling and personal growth for its employees.",
    gradient: '254, 127, 45, 0.25',
    // img: "https://avitechvideos.blob.core.windows.net/tranzitech-website-assets/Careers/shutterstock_2151104711%201.jpg?sv=2022-11-02&ss=bqtf&srt=sco&sp=rwdlacuptfxiy&se=2023-06-22T19:33:53Z&sig=3Yj6ht71oNUDbtL5Ie6GkHcuqfeIzmunYVbIA2DQDZ4%3D&_=1687434778463",
    img: 'https://avitechvideos.blob.core.windows.net/tranzitech-website-assets/Careers/shutterstock_1744993268.jpg',
  },

  {
    hoverColor: 'bg-[#61558B]',
    title: 'Tranzitech’s Party',
    gridSpan: 2,
    hoverText:
      "At Tranzitech, we believe in celebrating our achievements, having fun, and strengthening our bonds as a team. That's why we host an end-of-the-year party that brings all employees together to celebrate our successes, reflect on the year gone by, and look forward to new beginnings. Our end-of-the-year party provides an excellent opportunity to connect with colleagues, unwind, and enjoy a night of great food, music, and entertainment.",
    gradient: '97, 85, 139, 0.25',
    // img: "https://avitechvideos.blob.core.windows.net/tranzitech-website-assets/Careers/shutterstock_2241139213%201.jpg?sv=2022-11-02&ss=bqtf&srt=sco&sp=rwdlacuptfxiy&se=2023-06-22T19:33:53Z&sig=3Yj6ht71oNUDbtL5Ie6GkHcuqfeIzmunYVbIA2DQDZ4%3D&_=1687434806068",
    img: 'https://avitechvideos.blob.core.windows.net/tranzitech-website-assets/Careers/Rectangle-61.jpg',
  }
];

export const ourCulture =
  'https://avitechvideos.blob.core.windows.net/tranzitech-website-assets/Careers/shutterstock_1567573876%201.jpg?sv=2022-11-02&ss=bqtf&srt=sco&sp=rwdlacuptfxiy&se=2023-06-22T19:33:53Z&sig=3Yj6ht71oNUDbtL5Ie6GkHcuqfeIzmunYVbIA2DQDZ4%3D&_=1687433750652';

export const fromEmployees = [
  {
    id: 1,
    name: 'Damilola',
    title: 'Product Designer',
    imgUrl: Damilola,
    companyImgUrl: tranzitechAvitechLogos,
    about:
      'The key factor making Tranzitech an appealing location to work for me is the chance to collaborate  with some powerful business leaders to create amazing products',
  },
  // {
  //   id: 2,
  //   name: 'Nnamdi Chime',
  //   title: 'Co-founder and CEO',
  //   imgUrl:
  //     'https://avitechvideos.blob.core.windows.net/tranzitech-website-assets/Home/Rectangle 32.jpg',
  //   companyImgUrl: tranzitechAvitechLogos,
  //   about:
  //     'Nnamdi is an innovative leader with over 20 years of experience with deep competencies in Engineering services, Core Banking, Business Administration, and growing businesses.',
  // },
  // {
  //   id: 3,
  //   name: 'Damilola',
  //   title: 'Product Designer',
  //   imgUrl: dammy,
  //   companyImgUrl: tranzitechAvitechLogos,
  //   about:
  //   'The key factor making Tranzitech an appealing location to work for me is the chance to collaborate  with some powerful business leaders to create amazing products',
  // },
];
