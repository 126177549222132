import { FC, useState } from "react";
import blogAccessIcon from "../../../../assets/icons/blog-access-icon.svg";
import whiteAccessIcon from "../../../../assets/icons/white-access-icon.svg";

interface BlogCardProps {
  id: string;
  title: string;
  blogImg: string;
  date: string;
  tags: any;
  handleSelectedPost: (id: string, title: string) => void;
}

const BlogCard: FC<BlogCardProps> = ({
  id,
  blogImg,
  title,
  date,
  handleSelectedPost,
  tags,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseOver = () => {
    setIsHovered(true);
  };

  const handleMouseOut = () => {
    setIsHovered(false);
  };


  return (
    <div
      className="rounded-[8px] shadow-dark-shadow hover:bg-[#120051] hover:text-[#ffffff] transition-colors duration-100 cursor-pointer"
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      onClick={() => handleSelectedPost(id, title)}
    >
      <div className="overflow-hidden h-[170px]">
        <img
          src={blogImg}
          alt={title}
          className={`w-full h-auto ${
            isHovered && "transition-transform duration-300 transform scale-110"
          }`}
        />
      </div>

      <div className="px-[29px] pt-[30px]">
        <h2 className="font-RobotoBold">{title}</h2>
        {/* <div className="flex row mt-[10px]">
          {tags[0]?.split(",").map((item: any) => (
            <p
              className={`${
                isHovered ? "bg-[#fff] text-[#06001bcc] " : "bg-[#06001b0d]"
              } rounded-[6px] py-[5px] px-[10px] text-[10px] mr-[5px]`}
              key={item}
            >
              {item}
            </p>
          ))}
        </div> */}
        <div
          className={`flex flex-row justify-between border-t-[1px] ${
            isHovered
              ? "border-[#ffffff] text-[#ffffff]"
              : "border-[#06001b33] text-[#06001bcc]"
          }  pt-[16.5px] mt-[64px] pb-[26.5px] text-sm `}
        >
          <small>{date}</small>
          <div className="cursor-pointer">
            <img src={isHovered ? whiteAccessIcon : blogAccessIcon} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogCard;
