import { FC, useEffect } from 'react';

import Form from '../../components/Form/Form';
import Footer from '../../components/Footer';
import Header from '../../components/UiKits/Header';
import { scrollToTop } from '../../utils/scrollTop';

interface ContactProps {}

const Contact: FC<ContactProps> = () => {
  useEffect(() => {
    scrollToTop();
  });
  return (
    <div>
      <Header
        title='Contact Tranzitech'
        imgUrl='https://avitechvideos.blob.core.windows.net/tranzitech-website-assets/Contact Us/shutterstock_2218593073-1.jpg'
      />
      <div className='lg:flex lg:flex-row px-[4%]'>
        <div className='lg:w-[45%] pt-[23%]'>
          <h5>Get in touch</h5>
          <h2 className='text-4xl font-PoppinsBold'>Let’s Talk</h2>
          <p className='text-sm lg:w-[50%]'>
            Let's start a conversation and build a better future together.
          </p>
        </div>
        <div className='lg:w-[55%]'>
          <Form />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
