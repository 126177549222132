import React, { useState } from 'react';
import { tabContent } from '../../data/career-tabs';

const CareerTab = () => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index: number) => {
    setActiveTab(index);
  };

  const tabItems = [
    'Vision & Innovation',
    'Effective communication',
    'Ownership & accountability',
    'Emotional Intelligence',
    'Humility & Vulnerability',
    'Winning Together',
  ];

  const currentTabContent = tabContent[activeTab];

  return (
    <section className='px-5 sm:px-24'>
      <div className='flex   flex-col lg:flex-row  justify-center items-center lg:m-5'>
        <div className='w-full  my-5 lg:w-1/2 lg:order-1 order-2'>
          <ul className='list-disc   sm:mt-15 font-roboto font-normal text-base leading-6 px-[20px] lg:px-[0px]'>
            {currentTabContent.list.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </div>
        <div className='w-full mt-6 lg:w-1/2  lg:order-2 order-1'>
          <img
            className='w-full  h-[300px] rounded-lg object-cover'
            src={currentTabContent.img}
            alt=''
          />
        </div>
      </div>
      <div className='border-t-4 my-8  border-transparent max-w-full overflow-hidden'>
        <div className='flex overflow-scroll'>
          {tabItems.map((item, index) => (
            <button
              key={index}
              className={`flex-1 py-2 text-center h-[92px] sm:text-base text-xs border-t-4  p-16 pb-16 pl-10 pr-16 gap-10  ${
                index === activeTab
                  ? 'border-[#120051] text-[#120051] font-semibold'
                  : ''
              }`}
              onClick={() => handleTabClick(index)}
            >
              {item}
            </button>
          ))}
        </div>
      </div>
    </section>
  );
};

export default CareerTab;
