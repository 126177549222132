import React, { FC } from 'react';
import logoIcon from '../../assets/icons/tranzitech-logo-icon.svg';
import arrowRight from '../../assets/icons/arrow-right.svg';
import logoBanner from '../../assets/icons/tranzitech-logo-banner.svg';
import LearnMoreBTN from '../UiKits/LearnMoreBTN/LearnMoreBTN';

interface GetInTouchProps {
  title?: string;
  extraText?: string;
  linkName: string;
  linkUrl: string;
}
const GetInTouch: FC<GetInTouchProps> = ({
  title = 'Get in Touch',
  extraText = "Ready to Collaborate? Let's Talk!",
  linkName,
  linkUrl,
}) => {
  return (
    <section className='bg-[#120051] md:w-5/6  w-full  text-white rounded-[12px] relative'>
      <div className=' p-5 md:p-[80px] flex flex-col  text-center justify-center items-center '>
        <img
          className='mb-[24px] animate__animated  animate__fadeIn'
          src={logoIcon}
          alt=''
        />

        <p className='font-PoppinsBold  text-white lg:text-5xl text-[20px] lg:text-H1 mb-[24px] animate__animated animate__slideInUp'>
          {title}
        </p>

        {extraText && (
          <p
            className={`font-Poppins mb-[32px] animate__animated animate__slideInUp ${
              extraText === 'hidden' ? 'invisible' : 'visible'
            }`}
          >
            {extraText}
          </p>
        )}

        <LearnMoreBTN
          href={linkUrl}
          icon={arrowRight}
          title={linkName}
          lineColor='#ffffff'
          hoverLineColor='#ffffff'
          styles={'w-[129px]'}
        />

        {/* <p className=' block border-b-2 animate__animated animate__slideInUp'>
          <a href={linkUrl}>
            {' '}
            {linkName} <img className='inline' src={arrowRight} alt='' />{' '}
          </a>
        </p> */}
      </div>

      <aside className='w-2/12 absolute bottom-0 animate__animated animate__slideInLeft'>
        <img className='w-8/12' src={logoBanner} alt='' />
      </aside>
    </section>
  );
};

export default GetInTouch;
