import { FC } from 'react';
import { Link } from 'react-router-dom';
import arrowRight from '../../../assets/icons/arrow-right.svg';
import { Logos } from '../../../data/homepage-sliders';
import PortfolioCard from './PortfolioCard/PortfolioCard';

import '../../../styles/utility/slider.css';
import LearnMoreBTN from '../../UiKits/LearnMoreBTN/LearnMoreBTN';

interface PortfolioProps {}

const Portfolio: FC<PortfolioProps> = ({}) => {
  return (
    <section className='w-[100%] lg:h-[680px] lg:px-[56px] bg-purple-900 text-white py-[68px] lg:py-[0px] lg:flex lg:flex-row lg:justify-between overflow-hidden'>
      <div className='flex flex-row space-x-6 lg:space-x-0 lg:flex-col animate__animated animate__slideInDown'>
        {Logos.map((logo) => (
          <div
            key={logo.id}
            className='lg:grid lg:grid-cols-2 lg:gap-10 lg:justify-between'
          >
            <div className='slider__lines'>
              <PortfolioCard imgUrl={logo.imgUrl} />
            </div>
            <div className='slider__line hidden lg:block'>
              <PortfolioCard imgUrl={logo.imgUrl} />
            </div>
          </div>
        ))}
      </div>
      <div className='ml-[20px] px-[10px] lg:ml-[140px] lg:px-[20px] sm:w-[100%] sm:px-[20px] md:w-[40%] lg:w-[50%] xl:w-[50%] lg:py-[221px] lg:px-[60px] mt-[58px] lg:mt-[0px] animate__animated  animate__slideInRight'>
        <h1 className='text-xl pb-[26px] sm:pr-[0px] pr-[10px] md:pr-[4px] lg:pr-[0px] lg:text-H2 font-PoppinsNormal font-bold lg:pb-[32.5px] leading-[1.3]'>
          Innovative Ventures Driving{' '}
          <span className='text-[#FE7F2D]'>Progress</span> Across Africa
        </h1>
        <LearnMoreBTN
          href='/portfolio'
          icon={arrowRight}
          title='Learn more'
          lineColor='#ffffff'
          hoverLineColor='#ffffff'
          styles={'w-[129px]'}
        />
      </div>
    </section>
  );
};

export default Portfolio;
