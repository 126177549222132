import { FC } from 'react';

import './Slider.css';

type SliderType = {
  id: number;
  name: string;
  title: string;
  imgUrl: string;
  companyImgUrl: string;
  about: string;
};

interface SliderCarouselProps {
  slides: SliderType[];
  activeSlide: number;
}

const SliderCarousel: FC<SliderCarouselProps> = ({ slides, activeSlide }) => {
  return (
    <div className='slider-container h-[100%] w-[100%] shadow-light-shadow md:mb-[1%] rounded-[12px]'>
      <div
        className='slider-wrapper flex flex-row w-full'
        style={{ transform: `translateX(-${activeSlide * 100}%)` }}
      >
        {slides.map((slide, index) => (
          <div
            key={slide.id}
            className={`lg:flex lg:flex-row bg-white w-full rounded slide ${
              index === activeSlide ? 'active' : ''
            } ${
              index === activeSlide - 1 ||
              (activeSlide === 0 && index === slides.length - 1)
                ? 'previous'
                : ''
            } ${
              index === activeSlide + 1 ||
              (activeSlide === slides.length - 1 && index === 0)
                ? 'next'
                : ''
            }`}
          >
            <div className='lg:w-[50%] bg-[#1200510d]'>
              <img
                src={slide.imgUrl}
                className='lg:w-[450px] m-auto rounded-bl-[10px]'
              />
            </div>
            <div className='lg:pl-[33px] text-left lg:w-[50%] p-[12px]'>
              <div className='mb-[24px] '>
                <img
                  src={slide.companyImgUrl}
                  className='pt-[42px] pb-[24px]'
                />
              </div>
              <h1 className='text-lg font-PoppinsBold'>{slide.name}</h1>
              <small className='text-xs text-[#06001bcc]'>{slide.title}</small>
              <div
                dangerouslySetInnerHTML={{ __html: slide.about }}
                className='text-sm pt-[24px] pb-[25px] lg:pb-[10px] lg:pr-[96px] w-[100%]'
              />
              {/* <p className='text-sm pt-[24px] pb-[25px] lg:pb-[0px] lg:pr-[75px]'>
                {slide.about}
              </p> */}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SliderCarousel;
