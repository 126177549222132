import { FC } from "react";
import cardImg from "../../assets/images/AboutUsSession.svg";

interface OurCultureProps {}

const OurCulture: FC<OurCultureProps> = ({}) => {
  return (
    <section className=" p-5  sm:px-24 sm:py-10 ">
      <div className="   text-center  relative">
        <h3 className="font-PoppinsBold text-[#06001b99] text-sm md:text-lg animate__animated animate__slideInDown">
          Our Culture
        </h3>
        <h2 className="font-Poppins font-semibold  sm:text-3xl lg:w-[50%] sm:w-[70%] w-full  mx-auto text-[#06001bcc;] lg:pt-6 lg:leading-10 lg:[w-50%]   pt-3 animate__animated animate__slideInDown">
          We build trust and solve important problems together
        </h2>
      </div>
    </section>
  );
};

export default OurCulture;
