import React, { ReactNode } from 'react';

interface CardProps {
  header: string;
  body: string;
}

const DNACard: React.FC<CardProps> = ({ header, body }) => {
  return (
    <div className='lg:w-1/4 w-full md:h-[330px] lg:h-[420px] xl:h-[292px] mb-[16px] bg-opacity-5 bg-gray-800 rounded-lg'>
      <div className='p-4'>
        <h2 className='font-medium font-RobotoBold text-left text-base    leading-[30px] mb-2'>
          {header}
        </h2>
        <p className=' text-sm leading-2'>{body}</p>
      </div>
    </div>
  );
};

export default DNACard;
