import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import CookieConsent from 'react-cookie-consent';
import { Link } from 'react-router-dom';

interface CookieConsentComponentProps {}

const CookieConsentComponent: FC<CookieConsentComponentProps> = () => {
  const navigate = useNavigate();
  return (
    <CookieConsent
      style={{
        background: '#ffffff',
        color: '#06001BCC',
        // display: 'block',
        padding: '20px 31px',
        borderRadius: '40px 40px 0px 0px',
      }}
      overlay
      buttonText='Accept All'
      buttonStyle={{
        background: '#120051',
        color: '#fff',
        borderRadius: '12px',
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '20px',
        fontStyle: 'normal',
        padding: '8px 18px',
        marginTop: '60px',
        border: 0,
        letterSpacing: '0.0115em',
      }}
      setDeclineCookie={false}
      enableDeclineButton
      declineButtonText='Learn more'
      declineButtonStyle={{
        background: 'transparent',
        borderRadius: '12px',
        color: '#06001BCC',
        padding: '8px 18px',
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '20px',
        border: '1px solid #120051',
        letterSpacing: '0.0115em',
      }}
      onDecline={() => navigate('/cookie-policy')}
      hideOnDecline={false}
      flipButtons
      cookieName='tranzitech-cookie'
      // debug={true}
    >
      <div className='text-H2 font-PoppinsNormal text-[ #120051]'>
        Cookie Policy
      </div>
      <br />
      These cookies are used to collect information about how you interact with
      our website and allow us to remember you.
      <br />
      We use this information in order to improve and customize your browsing
      experience and for analytics and metrics. <br />
      For information about our cookies. By continuing, you agree to{' '}
      <Link to='/cookie-policy' style={{ color: '#06001BCC' }}>
        <b className='cliqki-pointer'>Tranzitech's cookie policy</b>{' '}
      </Link>
      and{' '}
      <Link to='/privacy-policy' style={{ color: '#06001BCC' }}>
        <b className='cliqki-pointer'>Tranzitech's privacy policy</b>
      </Link>
    </CookieConsent>
  );
};

export default CookieConsentComponent;
