import React from 'react';

const AboutHero = () => {
  return (
    <div className='w-full'>
      <div className='bg-aboutUsBgImage  bg-cover bg-contain bg-no-repeat h-auto  md:h-[100vh] w-full'>
        <div className='p-6 lg:py-20 lg:px-14 flex h-[300px] md:h-full flex-col justify-center  lg:items-start items-center'>
          <div className='font-PoppinsBold text-white w-full md:w-4/5 h-2/6'>
            <p className='font-PoppinsBold mb-3 lg:mb-8 text-xl md:text-left sm:text-4xl lg:text-4xl lg:leading-extraloose   animate__animated animate__bounceInLeft animate__slower'>
              Innovating Africa's Future for over a Decade
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutHero;
