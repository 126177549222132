import avitechLogo from '../assets/icons/avitech-logo.svg';
import tripsLogo from '../assets/icons/trips-logo.svg';
import dealerpayLogo from '../assets/icons/dealerpay-logo.svg';

import bgAvitech from '../assets/images/blog-card-image1.svg';
import LogoImage from '../components/Portfolio/AvitechLogoImage';
import TripsLogoImage from '../components/Portfolio/TripsLogoImage';
import DealerpayLogoImage from '../components/Portfolio/DealerpayLogoImage';

// export const porfolioCompaines = [
//     { name: 'Avitech', logo: `${avitechLogo}`, bgHoverImage: bgAvitech },
//     { name: 'Avitech', logo: `${tripsLogo}`, bgHoverImage: bgAvitech },
//     { name: 'Avitech', logo: `${dealerpayLogo}`, bgHoverImage: bgAvitech }
// ]
export const porfolioCompaines = [
  { name: 'avitech', logo: LogoImage, bgHoverImage:'bg-avitechHoverBgImage' ,animate:'slideInLeft' },
  { name: 'trips', logo: TripsLogoImage, bgHoverImage: 'bg-tripsHoverBgImage', animate:'slideInRight'},
  { name: 'deealerpay', logo: DealerpayLogoImage, bgHoverImage: '', animate: 'slideInUp' },
];

// bg-avitechHoverBgImage
// bg-avitechHoverBgImage