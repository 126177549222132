import { FC, useRef, useEffect, useState } from 'react';

import GetInTouch from '../../components/GetInTouch/GetInTouch';
import WhoWeAre from '../../components/HomePage/WhoWeAre/WhoWeAre';
import Hero from '../../components/HomePage/Hero/Hero';
import Footer from '../../components/Footer';
import BlogGrid from '../../components/HomePage/Blog/BlogGrid/BlogGrid';
import Portfolio from '../../components/HomePage/Portfolio/Portfolio';
import Founders from '../../components/Founders/Founders';
import CookieConsentComponent from './CookieConsent';
import Preloader from '../../components/preloader/Preloader';

// interface IHomeProps {
//   setIsLoading: (isLaoding: boolean) => void;
// }

const Home: FC = () => {
  const scrollRef = useRef<HTMLDivElement>(null);

  const handleScrollDown = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  };

  return (
    <div className='overflow-x-hidden'>
      <Hero scrollDown={handleScrollDown} />
      <div ref={scrollRef}>
        <WhoWeAre />
      </div>

      <Portfolio />
      <Founders />
      <BlogGrid />
      <div className=' flex  justify-center mb-8  md:mb-[120px] p-5 '>
        <GetInTouch linkName='Learn More' linkUrl='/about-us' />
      </div>
      <CookieConsentComponent />
      <Footer />
    </div>
  );
};

export default Home;
