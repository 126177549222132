import React, { useEffect, useState } from 'react';
import imgUrle from '../../../assets/images/blog-card-image1.svg';
import { lifeAtTranzitech } from '../../../../data/careers';

type Cardprops = {
  logo?: any;
  hoverColor?: string;
  hoverText: string;
  gradient: string;
  index?: number;
  // handleClick?: () => void;
  // animateType?: string;
  title: string;
  gridSpan?: number;
  img: string;
};
type Iprops = {
  item: Cardprops;
  index: number;
};

const LifeAtTranzitechCardNew: React.FC<Iprops> = (props) => {
  const { item, index } = props;

  const span = item.gridSpan === 2 ? 'col-span-2' : 'col-span-1';
  const hoverColor =
    item.hoverColor === 'bg-[#477C51]'
      ? 'after:hover:bg-[#477C51]'
      : item.hoverColor === 'bg-[#61558B]'
      ? 'after:hover:bg-[#61558B]'
      : item.hoverColor === 'bg-[#FE7F2D]'
      ? 'after:hover:bg-[#FE7F2D]'
      : 'after:hover:bg-[#5C00DF]';
  const mystyle = {
    backgroundImage: `linear-gradient(to bottom, rgba(${item.gradient}) 0%, rgba(${item.gradient}) 80.60%), url("${item.img}")`,
  };

  function randNum(arr: string | any[], excludeNum: number): number {
    var randNumber = Math.floor(Math.random() * arr.length);
    if (arr[randNumber] == excludeNum) {
      return randNum(arr, excludeNum);
    } else {
      return randNumber;
    }
  }

  //  // Shuffle the elements array and set the first 9 as displayed elements
  //  const shuffleElements = () => {
  //   const shuffledArray = [...elements].sort(() => 0.5 - Math.random());
  //   const displayed = shuffledArray.slice(0, 9);
  //   setDisplayedElements(displayed);
  // };
  function generateRandNum(arr: string | any[], excludeNum: number) {
    var randNumber = Math.floor(Math.random() * arr.length);
    if (arr[randNumber] === excludeNum) {
      randNum(arr, excludeNum);
    } else {
      return randNumber;
    }
  }
  //   useEffect(() => {
  //     const interval = setInterval(() => {
  //       const shuffledArray = lifeAtTranzitech.filter(
  //         (item, idx) => item.gridSpan === item.gridSpan
  //       );
  //       const randNumber = generateRandNum(shuffledArray, index);
  //       setitem(shuffledArray[randNumber as number]);
  //     }, 10000);

  //     return () => clearInterval(interval);
  //   }, [item]);

  // const imgHover = "after:hover:" + bgHoverColor?.trim();

  return (
    <div
      className={`group ${span}   hover:bg-transparent    sm:w-[250px]  p-5 rounded-lg  z-10    
    after:top-full  after:h-0 after:z-0  after:ease-in  after:hover:top-0 after:transition-all after:hover:duration-1000 after:hover:ease-in-out
     ${hoverColor}  bg-cover h-40   bg-no-repeat    after:hover:w-full   after:hover:absolute after:hover:animate__slideInUp relative `}
      style={mystyle}
    >
      <div className='w-full z-50'>
        <h2 className='text-white font-Roboto  leading-extraloose sm:text-2xl text-sm mb-6'>
          {item.title}
        </h2>
        <p className='invisible group-hover:visible text-white md:block  hidden'>
          {item.hoverText}
        </p>
      </div>
      {/* </div> */}
    </div>
  );
};

export default LifeAtTranzitechCardNew;
