  //for copy link
  export const copyToClipboard = (hrefRef: any) => {
    if (hrefRef.current) {
      hrefRef.current.select();
      document.execCommand('copy');
    }
  };

   //for share via email
  export const handleShareEmail = (postUrl:string) => {
      const recipientEmail = '';
      const subject = `Check this post out`;
      const body = `${postUrl}`;

    const mailtoLink = `mailto:${recipientEmail}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;
    window.location.href = mailtoLink;
  };

  //for twitter
  export const handleTwitterShare = (postUrl:string) => {
    const tweetText = `Check this  post`; // The text you want to share
    const tweetUrl = `${postUrl}`; // The URL you want to share

    const twitterUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
      tweetText
    )}&url=${encodeURIComponent(tweetUrl)}`;

    window.open(twitterUrl, '_blank');
  };

    // for linkedin
  export const handleLinkedInShare = (postUrl:string) => {
    window.open(
      `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
        `${postUrl}`
      )}&title=${encodeURIComponent(
        'Check this post'
      )}&summary=${encodeURIComponent('')}`,
      '_blank',
      'width=520,height=570,scrollbars=yes'
    );
  };