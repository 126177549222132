export const cardData = [
    { header: 'Vision & Innovation', 
    body: 'Our strides is determined by fostering the research and creation of a broader range  of product and services'},

    { header: 'Integrity', 
    body: 'Togetherness, consistency and uncompromising adherence to our principles and values'},

    { header: 'Excellence', 
    body: 'When we meet the highest standard of quality, we strive to break those standards and set new marks in delivery to our customers, or improvement in our processes'},

    { header: 'Trust', 
    body: 'We remain humble to realize that one person cannot change the world, and so we cover each other’s weaknesses by understanding and depending on our strengths'},

    { header: 'Ownership & Accountability', 
    body: 'Trust is complete when we individually take ownership and responsibility for the outcome'},
] 

export const tZData = [
    { header: 'Continuous Growth', 
    body: 'At Tranzitech, we prioritize our employees growth and development. Plus, we offer a competitive rewards plan and a supportive, inclusive culture where everyone can thrive.'},

    { header: 'Leadership Development', 
    body: 'We prioritize leadership development because we believe that it’s the key to our continued growth and success. We understand that effective leadership comes from developing strong relationship, fostering a positive work culture and providing our employees with the tools they need to succeed'},

    { header: 'Focused on Scaling', 
    body: 'As we continue to push the boundaries of whats possible with technology, we are committed to attracting and developing top talent who are passionate about innovation and making a difference. '},

    { header: 'Driven by Values', 
    body: 'Despite of our diverse histories and cultures inside the Tranzitech network, we share a common principle. Our values govern our interaction with our clients and colleagues and they hold us accountably fro delivering the greatest possible performance in all circumstances.'},
] 
