import React, { CSSProperties } from 'react';
type ButtonProps = {
  handleClick?: () => void;
  label: string;
  size?: 'Large' | 'Medium' | 'Small';
  type?: any;
  isLoading?: boolean;
  disabled?: boolean;
  className?: CSSProperties;
  btnClass?: string;
};

const Button: React.FC<ButtonProps> = ({
  label,
  className,
  isLoading,
  btnClass,
}) => {
  return (
    <button
      className={`${btnClass} w-full p-4 font-Roboto flex justify-center items-center text-lg text-white  h-[48px] rounded-[12px] ${
        className && className
      } `}
      disabled={isLoading}
    >
      {label}
    </button>
  );
};

export default Button;
