/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Dialog } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';

import logo from '../../../assets/icons/logo.svg';
import darkLogo from '../../../assets/icons/tranzitech-logo.svg';

type navigationType = {
  name: string;
  href: string;
};

const navigation: navigationType[] = [
  { name: 'About Us', href: '/about-us' },
  { name: 'Portfolio', href: '/portfolio' },
  { name: 'Careers', href: '/careers' },
  { name: 'Media', href: '/media' },
  { name: 'Contact', href: '/contact-us' },
];

const classNames = (...classes: string[]) => {
  return classes.filter(Boolean).join(' ');
};

const Nav = () => {
  const location = useLocation();

  const [isNavbarTransparent, setNavbarTransparent] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const shouldNavbarBeTransparent = window.scrollY === 0;
      setNavbarTransparent(shouldNavbarBeTransparent);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const { pathname } = location;

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseOver = () => {
    setIsHovered(true);
  };

  const handleMouseOut = () => {
    setIsHovered(false);
  };

  return (
    <header
      className={`fixed ${
        isHovered && isNavbarTransparent ? 'bg-[#ffffff27]' : ''
      } ${!isNavbarTransparent && 'bg-[#ffffff]'}  inset-x-0 top-0 z-50`}
    >
      <nav
        className='flex items-center justify-between p-6 lg:px-14'
        aria-label='Global'
      >
        <div className='flex lg:flex-1'>
          <a href='/' className='-m-1.5 p-1.5'>
            <span className='sr-only'>Tranzitech Holding</span>
            <img
              className='h-8 w-auto'
              src={
                pathname.startsWith('/media/news/') ||
                pathname.startsWith('/cookie-policy') ||
                pathname.startsWith('/privacy-notice') ||
                pathname.startsWith('/privacy-policy') ||
                !isNavbarTransparent
                  ? darkLogo
                  : logo
              }
              alt=''
            />
          </a>
        </div>
        <div className='flex lg:hidden'>
          <button
            type='button'
            className='-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-white '
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className='sr-only'>Open main menu</span>
            <Bars3Icon
              className={`h-6 w-6  ${
                (pathname.startsWith('/media/news/') ||
                  pathname.startsWith('/cookie-policy') ||
                  pathname.startsWith('/privacy-notice') ||
                  pathname.startsWith('/privacy-policy')) ||
                !isNavbarTransparent
                  ? 'text-[#120051]'
                  : 'text-[#ffffff]'
              }`}
              aria-hidden='true'
            />
          </button>
        </div>

        <div className='hidden lg:flex lg:flex-1 lg:justify-end'>
          <div className='hidden lg:flex lg:gap-x-8'>
            {navigation.map((item) => (
              <a
                key={item.name}
                href={item.href}
                onMouseOver={handleMouseOver}
                onMouseOut={handleMouseOut}
                className={
                  pathname.startsWith('/media/news/') ||
                  pathname.startsWith('/cookie-policy') ||
                  pathname.startsWith('/privacy-notice') ||
                  pathname.startsWith('/privacy-policy') ||
                  !isNavbarTransparent
                    ? classNames(
                        pathname === item.href
                          ? 'border-b border-[#120051] text-[#120051] text-sm font-semibold leading-6 '
                          : 'text-[#120051]  hover:bg-none hover:text-[#120051] hover:border-b hover:border-[#120051]',
                        'mx-1 my-2 text-sm font-medium '
                      )
                    : classNames(
                        pathname === item.href
                          ? 'border-b border-white text-white text-sm font-semibold leading-6  '
                          : 'text-white hover:bg-none hover:text-white hover:border-b hover:border-white',
                        'mx-1 my-2 text-sm font-medium'
                      )
                }
                aria-current={pathname === item.href ? 'page' : undefined}
              >
                {item.name}
              </a>
            ))}
          </div>
        </div>
      </nav>
      <Dialog
        as='div'
        className='lg:hidden'
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className='fixed inset-0 z-50' />
        <Dialog.Panel className='fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10'>
          <div className='flex items-center justify-between'>
            <a href='/' className='-m-1.5 p-1.5'>
              <span className='sr-only'>Tranzitech</span>
              <img className='h-8 w-auto' src={darkLogo} alt='' />
            </a>
            <button
              type='button'
              className='-m-2.5 rounded-md p-2.5 text-black-700'
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className='sr-only'>Close menu</span>
              <XMarkIcon
                className='h-6 w-6 text-black-900'
                aria-hidden='true'
              />
            </button>
          </div>
          <div className='mt-6 flow-root'>
            <div className='-my-6 divide-y divide-gray-500/10'>
              <div className='space-y-2 py-6'>
                {navigation.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className='-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-transparent'
                  >
                    {item.name}
                  </a>
                ))}
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
};

export default Nav;
