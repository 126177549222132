import React, { ReactNode } from 'react';
import rightArrow from '../../assets/icons/arrow-right-black.svg';
import { Link } from 'react-router-dom';
import LearnMoreBTN from '../UiKits/LearnMoreBTN/LearnMoreBTN';

interface PortFolioProps {
  header1: ReactNode;
  content1: ReactNode;
  content2: string;
}

const PortFolio: React.FC<PortFolioProps> = ({
  header1,
  content1,
  content2,
}) => {
  return (
    <div className=' px-5 sm:p-24 ' style={{ backgroundColor: '#FFFCFB' }}>
      <div className='w-full flex flex-col lg:flex-row justify-between'>
        <div className='sm:mt-10 lg:w-[25%]  w-full'>
          <h2 className='lg:text-3xl font-semibold font-Poppins sm:text-2xl text-xl my-8  animate__animated animate__bounceInLeft animate__slower'>
            {header1}
          </h2>
        </div>
        <div className='border-r-2 border-gray-400mx-4 hidden lg:block '></div>
        <div className=' flex w-full lg:w-[45%]  flex-col'>
          <div className=''>
            <p className='font-normal   lg:text-base leading-6 sm:text-xs   pb-5 animate__animated animate__bounceInRight animate__slower'>
              {content1}
            </p>
          </div>
          <LearnMoreBTN
            title={content2}
            icon={rightArrow}
            href='/portfolio'
            lineColor='#06001b'
            hoverLineColor='#06001b'
            styles={
              'w-[149px]  animate__animated animate__bounceInRight animate__slower  my-6'
            }
            lineColorStyles={' w-[149px] m-auto'}
          />
          {/* <div className=' w-full   my-6 '>
            <p className='font-bold'>
              <Link to='/portfolio'>
                <span className='border-b-2 border-black inline-block hover:cursor-pointer '>
                  {content2} <img className='inline' src={rightArrow} alt='' />
                </span>
              </Link>
            </p>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default PortFolio;
