export const BASE_API_URL =
  process.env.NODE_ENV === "development"
    ? `${process.env.REACT_APP_API_BASEURL}`
    : //@ts-ignore
      `${window.env?.apiURL}`;

export const App_URL =
  process.env.NODE_ENV === "development"
    ? `localhost:3000`
    : //@ts-ignore
      `${window.env?.CUSTOM_DOMAIN_NAME}`;
