import { FC } from 'react';

import rightArrow from '../../../assets/icons/arrow-right-black.svg';
import logoBanner from '../../../assets/icons/complete-tranzitech-logo-banner.svg';
import LearnMoreBTN from '../../UiKits/LearnMoreBTN/LearnMoreBTN';

interface WhoWeAreProps {}

const WhoWeAre: FC<WhoWeAreProps> = () => {
  return (
    <section className='flex-1 lg:px-[260px] lg:py-[120px] text-center pt-[70px] relative'>
      <h3 className='font-PoppinsBold text-[#06001b99] text-sm md:text-lg animate__animated animate__slideInDown'>
        Who we are
      </h3>
      <h2 className='font-Poppins font-bold sm:text-mobileH2 lg:text-H2 text-xl text-[#06001bcc;] lg:pt-6 leading-[125%] pt-[32px] pb-[32px] animate__animated animate__slideInDown'>
        We are Driving Progress and Innovation through Technology Solutions
      </h2>
      <p className='text-sm md:text-lg p-2 sm:p-8 md:p-12 lg:pt-2 animate__animated animate__slideInUp'>
        Revolutionizing the tech industry, Tranzitech is a leading technology
        holding company that empowers growth and innovation for its portfolio
        companies through cutting-edge solutions and expertise
      </p>

      <LearnMoreBTN
        href='/about-us'
        title='Learn more'
        icon={rightArrow}
        lineColor='#06001b'
        hoverLineColor='#06001b'
        styles={'mt-[64px] w-[129px] m-auto'}
        lineColorStyles={' m-auto'}
      />
      {/* <div className='text-base flex flex-row justify-center items-center w-[126px] m-auto mt-[64px] font-PoppinsBold border-b-2 border-[#06001b] mb-[36px] hover:border-[#06001b30]  transition-colors duration-100 cursor-pointer infinite animate__animated animate__slideInUp'>
        <a href='/about-us'> Learn more </a>
        <span>
          <img src={rightArrow} alt='learn more' />
        </span>
      </div> */}

      <aside className='w-2/12 left-[-9%] absolute bottom-0 md:bottom-[-7%] lg:bottom-[29%] hover:mr-[80%] transition-colors duration-100 infinite animate__animated animate__slideInLeft'>
        <img className='' src={logoBanner} alt='' />
      </aside>
    </section>
  );
};

export default WhoWeAre;
