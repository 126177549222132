import { FC, useEffect } from 'react';
import { Link } from 'react-router-dom';

import LandingPage from '../../components/LandingPage/LandingPage';
import { scrollToTop } from '../../utils/scrollTop';

interface IPrivacyNoticeProps {}

const PrivacyNotice: FC<IPrivacyNoticeProps> = () => {
  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <LandingPage
      title='Privacy Notice'
      contentBody={
        <>
          <div className='text-[#06001bcc]'>
            <p>
              This Privacy Notice applies to the{' '}
              <strong>Tranzitech Holding</strong> website inquiry
              page(www.tranzitechholding.com). The privacy of our users is
              important to us, and we are committed to safeguarding it. Hence,
              this privacy notice explains the personal information which we
              collect, why we collect it, and what we do with it.
            </p>
            <br />
            <h1 className='text-base lg:text-2xl font-RobotoNormal'>
              PERSONAL DATA COLLECTED: (NAME AND EMAIL ADDRESS)
            </h1>{' '}
            <br />
            <p>
              We do not carry out automated decision-making on personal data
              collected. We process personal data based on consent to:
            </p>
            <br />
            <ol className=''>
              <li>1. Respond to your inquiries and fulfill your requests.</li>
              <li>
                2. Deliver marketing communications about our products and
                services.
              </li>
              <li>
                3. Carry out marketing analysis and make general improvements to
                our Site.
              </li>
            </ol>
            <br />
            <h1 className='text-base lg:text-2xl font-RobotoNormal'>
              Retention Period
            </h1>
            <br />
            <p>
              <strong>Tranzitech Holding</strong> only stores data for the
              purpose which it was collected for. Personal data are stored
              quaterly with appropriate technical and organizational measures
              subject to legal and regulatory requirements.
            </p>{' '}
            <br />
            <h1 className='text-base lg:text-2xl font-RobotoNormal'>
              Further Processing
            </h1>
            <p>
              <br />
              <strong>Tranzitech Holding</strong> shall request for data subject
              consent for additional processing of personal data other than the
              lawful purpose of processing stated above.
            </p>
            <br />
            <p>
              Kindly refer to our{' '}
              <Link className='text-[#120051] underline' to='/privacy-policy'>
                Privacy Policy
              </Link>{' '}
              for more details on our data privacy and protection culture.
            </p>{' '}
            <br />
            <p>
              By clicking “SUBMIT” you agree <strong>Tranzitech Holding</strong>{' '}
              can collect and process your personal data in accordance to this{' '}
              <Link className='text-[#120051] underline' to='/privacy-notice'>
                privacy notice
              </Link>{' '}
              and our website{' '}
              <Link className='text-[#120051] underline' to='/privacy-policy'>
                privacy policy
              </Link>
              .
            </p>{' '}
            <br />
            <p className='text-sm lg:text-base font-RobotoBold'>
              Date of last revision: 1st June 2021
            </p>
          </div>
        </>
      }
    />
  );
};

export default PrivacyNotice;
