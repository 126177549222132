import { FC } from 'react';

type Cardprops = {
  logo?: any;
  hoverColor?: string;
  hoverText: string;
  gradient: string;
  index?: number;
  title: string;
  gridSpan?: number;
  img: string;
};
type Iprops = {
  item: Cardprops;
  index: number;
};

const LifeAtTranzitechCardTest: FC<Iprops> = (props) => {
  const { item } = props;

  const span = item.gridSpan === 2 ? 'col-span-2' : 'col-span-1';
  const hoverColor =
    item.hoverColor === 'bg-[#477C51]'
      ? 'after:hover:bg-[#477C51]'
      : item.hoverColor === 'bg-[#61558B]'
      ? 'after:hover:bg-[#61558B]'
      : item.hoverColor === 'bg-[#FE7F2D]'
      ? 'after:hover:bg-[#FE7F2D]'
      : 'after:hover:bg-[#5C00DF]';
  const mystyle = {
    backgroundImage: `linear-gradient(to bottom, rgba(${item.gradient}) 0%, rgba(${item.gradient}) 80.60%), url("${item.img}")`,
  };

  return (
    <div
      className={`group ${span}   hover:bg-transparent  p-5 rounded-lg  z-10  w-full     h-90 md:h-64   flex justify-center  
    after:top-full  after:h-0 after:z-0  after:ease-in  after:hover:top-0 after:transition-all after:hover:duration-1000 after:hover:ease-in-out
     ${hoverColor}  bg-cover    bg-no-repeat h-[100%]   after:hover:w-full  lg:after:hover:h-64  after:hover:h-40 after:hover:absolute after:hover:animate__slideInUp relative `}
      style={mystyle}
    >
      <div className='w-full z-50'>
        <h2 className='text-white font-Roboto  leading-extraloose text-2xl mb-2'>
          {item.title}
        </h2>
        <p className='invisible group-hover:visible text-white text-sm'>
          {item.hoverText}
        </p>
      </div>
    </div>
  );
};

export default LifeAtTranzitechCardTest;
