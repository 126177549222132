import React, { ReactNode } from 'react';

interface VisionMissionProps {
  logoSrc: string;
  //   companyName: string;
  header1: ReactNode;
  content1: ReactNode;
  header2: ReactNode;
  content2: ReactNode;
  header3: ReactNode;
}

const VisionMission: React.FC<VisionMissionProps> = ({
  logoSrc,
  header3,
  header1,
  content1,
  header2,
  content2,
}) => {
  return (
    <div
      className='px-5 sm:px-24 lg:pt-24 pb-2 '
      style={{ backgroundColor: '#FFFCFB' }}
    >
      <div className=' w-full flex flex-col lg:flex-row justify-between'>
        <div
          className='  w-full lg:justify-self-center  lg:w-[35%]'
          // style={{margin: "80px"}}
        >
          <h2 className='lg:text-3xl font-semibold font-Poppins sm:text-2xl text-xl my-8 mb-[32px]  animate__animated animate__bounceInLeft animate__slower'>
            {header1}
          </h2>
        </div>
        <div className='border-r-2 border-gray-400 mx-4 hidden lg:block '></div>
        <div className='flex w-full lg:w-[45%]  flex-col '>
          <div className=' '>
            <h2 className='font-semibold font-Poppins sm:text-2xl text-lg mb-4 animate__animated animate__bounceInRight animate__slower'>
              {header2}
            </h2>
            <p className='lg:text-sm  sm:text-base text-sm mb-4 mt-[16px] animate__animated animate__bounceInRight animate__slower'>
              {content1}
            </p>
          </div>

          <div className=' '>
            <h2 className='font-semibold font-Poppins sm:text-2xl text-lg mb-4 animate__animated animate__bounceInRight animate__slower'>
              {header3}
            </h2>
            <p className='lg:text-sm sm:text-base  text-sm animate__animated animate__bounceInRight animate__slower'>
              {content2}
            </p>
          </div>
        </div>
      </div>
      <div className='w-full  flex justify-center items-center lg:my-8'>
        <div className=' w-2/6 max-w-[500px]'>
          <img
            className=' opacity-5  w-full   filter brightness-50  '
            src={logoSrc}
            alt='Logo'
          />
        </div>
      </div>
    </div>
  );
};

export default VisionMission;
