import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import "./App.css";
import Home from "./pages/home/Home";
import AboutUs from "./pages/AboutUs/About-us";
import Post from "./pages/Post/Post";
import Nav from "./components/UiKits/Nav/Nav";
import Portfolio from "./pages/portfolio/Portfolio";
import Careers from "./pages/careers/Careers";
import Media from "./pages/media/Media";
import Contact from "./pages/contact/Contact";
import CookiePolicy from "./pages/CookiePolicy/CookiePolicy";
import PrivacyNotice from "./pages/PrivacyNotice/PrivacyNotice";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import Preloader from "./components/preloader/Preloader";
import { getLocalStorageItem } from "./utils/cache";
import NotFound from "./pages/NotFound/NotFound";

function App() {
  const [value, setValue] = useState<string>();

  const cachedValue = getLocalStorageItem(
    "tranzitech-preloader",
    24 * 60 * 60 * 1000
  );

  useEffect(() => {
    setTimeout(() => {
      setValue(cachedValue);
    }, 3000);
  }, [cachedValue]);

  if (!cachedValue) {
    return <Preloader />;
  }

  return (
    <BrowserRouter>
      <Nav />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/careers" element={<Careers />} />
        <Route path="/media" element={<Media />} />
        <Route path="/media/news/:title" element={<Post />} />
        <Route path="/contact-us" element={<Contact />} />
        <Route path="/cookie-policy" element={<CookiePolicy />} />
        <Route path="/privacy-notice" element={<PrivacyNotice />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        {/* <Route path="*" element={<NotFound />} /> */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
