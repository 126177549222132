import React, { useEffect } from 'react';
import HeroAbout from '../../components/AboutUsHero/about-us';
import Footer from '../../components/Footer';
import AboutImage from '../../assets/images/AboutUsHero.jpg';
import AboutSession from '../../components/AboutSession/about-session';
import AboutSessionImg from '../../assets/images/AboutSession.jpg';
import VisionMission from '../../components/VisionMission/vision-mission';
import logo from '../../assets/icons/logo.svg';
import DNACard from '../../components/DNACards/dna-cards';
import GetInTouch from '../../components/GetInTouch/GetInTouch';
import PortFolio from '../../components/PortfolioAboutUs/portfolio';
import { cardData } from '../../data/dnacard';
import AboutHero from '../../components/AboutUsHero/about-us';
import { scrollToTop } from '../../utils/scrollTop';

const AboutUs = () => {
  useEffect(() => {
    scrollToTop();
  });
  return (
    <div className='py-0 overflow-hidden'>
      <div className='overflow-x-hidden'>
        <AboutHero />
      </div>
      <div>
        <AboutSession
          headerText={'About Us'}
          firstText={
            <span>
              Tranzitech is a technology holding company that is at the
              forefront of innovation and provides a powerful platform for
              growth which serves as a holding pillar for her portfolio
              companies.
              <br />
              <br />
              Tranzitech Companies have specialized competence in solving a wide
              range of data processing, revenue assurance, inventory management,
              and embedded finance challenges through various product and
              technology solutions.
            </span>
          }
          secondText={
            <span className=''>
              Tranzitech helps Innovative
              <br />
              Companies Become
              <br />
              <span style={{ color: '#FE7F2D' }}>Legendary</span>
            </span>
          }
        />
      </div>
      <VisionMission
        logoSrc={logo}
        header1={'Vision & Mission'}
        header2={'Our Vision'}
        header3={'Our Mission'}
        content1={
          <p>
            To build and accelerate premium technology companies
            <br />
            that become the technology core for propelling innovation and growth
            in Africa.
          </p>
        }
        content2={
          <p>
            To connect Africa's technology platforms to provide revenue
            <br />
            assurance, inventory distribution, and access to embedded finance in
            <br />
            multiple markets across Nigeria and Africa by leveraging technology,
            <br />
            efficient people, and processes.
          </p>
        }
      />
      <div className='  bg-white  px-5 sm:p-24 mt-[164px]   '>
        <div className='flex flex-col'>
          <div className=''>
            <p className='font-normal text-[12px]  lg:text-base leading-6 lg:mb-6 text-[#06001b99] '>
              Our Core Values
            </p>
          </div>
          <div className=''>
            <h2 className='lg:text-3xl leading-9 font-bold text-left sm:text-2xl'>
              The Tranzitech DNA
            </h2>
          </div>
        </div>

        {/* Tranzitech DNA */}
        <div className='flex flex-col items-center gap-4  lg:flex-row lg:justify-between mt-12'>
          {cardData.map((card, index) => (
            <DNACard key={index} header={card.header} body={card.body} />
          ))}
        </div>
      </div>

      <div>
        <PortFolio
          header1={<span>Our Portfolio Companies</span>}
          content1={
            'Our portfolio companies drive innovation and progress through cutting-edge technology solutions in a variety of industries. From data processes to revenue assurance, inventory management to embedded finance, our companies are revolutionizing the tech landscape in Nigeria and beyond. Explore our dynamic and disruptive portfolio and join us in shaping the future'
          }
          content2='View Portfolio'
        />
      </div>
      <div className='flex p-5 justify-center mb-8 md:mb-[120px]'>
        <GetInTouch linkName='Contact Us' linkUrl='/contact-us' />
      </div>
      <Footer />
    </div>
  );
};

export default AboutUs;
