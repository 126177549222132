import { FC, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import BlogCard from "../../components/HomePage/Blog/BlogCard/BlogCard";
import MediaLibrary from "../../components/MediaLibrary/MediaLibrary";
import Footer from "../../components/Footer";
import Header from "../../components/UiKits/Header";
import { scrollToTop } from "../../utils/scrollTop";
import { fetchPosts } from "../../utils/services/posts.service";
import { Spin, message } from "antd";
import moment from "moment";
import { Posts } from "../../data/homepage-sliders";

interface IMediaProps {}

const Media: FC<IMediaProps> = () => {
  const navigate = useNavigate();
  const [allPosts, setAllPosts] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [pageSize, setPageSize] = useState(6);
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    scrollToTop();
  });

  const handleLoadMore = () => {
    setPageSize((prevValue) => prevValue + 3);
  };

  const fetchPostsData = useCallback(async () => {
    setIsLoading(true);
    const payload: any = {
      pageSize: pageSize,
      pageNumber: 1,
      parameter: {
        query: "",
      },
    };
    try {
      const res = await fetchPosts(payload);
      setAllPosts(res.data.data.object);
    } catch (error) {
      messageApi.open({
        type: "error",
        content:
          "There was an error while trying to fetch posts, please refresh your browser.",
      });
    }

    setIsLoading(false);
  }, [pageSize]);

  // useEffect(() => {
  //   (async () => await fetchPostsData())();
  // }, [pageSize]);

  useEffect(() => {
    setAllPosts(Posts);
  }, [Posts, setAllPosts]);

  const selectedPost = (id: string, title: string) => {
    const blogTitle = title.replace(/ /g, "-");
    navigate(`/media/news/${blogTitle}&${id}`);
  };

  return (
    <div className="relative">
      <div className="absolute"> {contextHolder}</div>
      <Header
        title="Media"
        imgUrl="https://avitechvideos.blob.core.windows.net/tranzitech-website-assets/Media/Tranzitech-News-1.jpg"
      />
      <div className="px-[4%] py-[10%]">
        <div className="flex flex-row mb-[5%]">
          <h2 className="text-4xl font-PoppinsBold">News</h2>
          <p></p>
        </div>
        {isLoading ? (
          <div className="h-screen flex justify-center items-center">
            <Spin />
          </div>
        ) : !allPosts.length ? (
          <div className="h-screen flex justify-center items-center">
            <p>There is active no post</p>
          </div>
        ) : (
          <div>
            <div className="grid lg:grid-cols-3 gap-10 justify-between animate__animated  animate__slideInRight">
              {allPosts.map((post) => (
                <div key={post.id}>
                  <BlogCard
                    title={post.title}
                    blogImg={post.bannerUrl}
                    date={moment(post.dateCreated).format("LL")}
                    id={post.id}
                    handleSelectedPost={() => selectedPost(post.id, post.title)}
                    tags={post.tags}
                  />
                </div>
              ))}
            </div>
            <p
              className="flex justify-center items-center text-[#120051] font-bold text-[16px] underline mt-[50px] cursor-pointer"
              onClick={handleLoadMore}
            >
              Load More
            </p>
          </div>
        )}

        <MediaLibrary />
      </div>
      <Footer />
    </div>
  );
};

export default Media;
