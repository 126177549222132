import axios from "axios";
import { BASE_API_URL } from "../constant";

export const fetchPosts = async (payload: any) => {
  const data = await axios.post(
    `${BASE_API_URL}/api/Blog/GetAllActiveBlogs`,
    payload
  );
  return data;
};

export const fectPost = async (payload: any) => {
  const data = await axios.post(
    `${BASE_API_URL}/api/Blog/GetBlogById`,
    payload
  );
  return data;
};
