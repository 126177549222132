import { FC } from 'react';

interface IHeaderProps {
  title: string;
  imgUrl: string;
}

const Header: FC<IHeaderProps> = ({ title, imgUrl }) => {
  return (
    <div className='relative'>
      <div className='absolute bg-black bg-opacity-60 w-[100%] h-[100%] animate__animated animate__slideInDown '></div>
      <h1 className='absolute text-white text-2xl lg:text-5xl top-[60%] lg:top-[40%] ml-[7%] lg:ml-[4%] font-PoppinsBold'>
        {title}
      </h1>
      <img src={imgUrl} alt='contact hero header' />
    </div>
  );
};

export default Header;
