import React, { useEffect, useState } from 'react';
import LifeAtTranzitechMobileView from './MobileView/LifeAtTranzitechMobileView';
import LifeAtTranzitechWebView from './WebView/LifeAtTranzitech';
import LifeAtTranzitechWebViewNew from './WebView/LifeAtTranzitechNew';
import LifeAtTranzitechMobileViewNew from './MobileView/LifeAtTranzitechMobileViewNew';

const LifeAtTranzitech = () => {
  const [browserWidth, setBrowserWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setBrowserWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [browserWidth]);

  return (
    <div className='overflow-hidden'>
      {' '}
      <h1 className='lg:text-H2 pl-[3%]  lg:pl-[5%] font-bold mt-[80px] mb-[40px]  lg:mb-[0px]  lg:mt-[120px] text-xl md:text-xl'>
        Life at Tranzitech
      </h1>{' '}
      {/* {browserWidth < 767 && <LifeAtTranzitechMobileView />}{' '} */}
      {browserWidth < 767 && <LifeAtTranzitechMobileViewNew />}{' '}
      {/* {browserWidth > 767 && <LifeAtTranzitechWebView />} */}
      {browserWidth > 767 && <LifeAtTranzitechWebViewNew />}
    </div>
  );
};

export default LifeAtTranzitech;
