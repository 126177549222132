import { FC } from 'react';
import { Modal } from 'antd';

interface IModalProps {
  title: string;
  content: string;
  href: string;
  logo: string;
  isModalOpen: boolean;
  handleSiteVisited?: boolean;
  handleCancel: () => void;
}

const ModalComponent: FC<IModalProps> = ({
  title,
  content,
  logo,
  href,
  isModalOpen,
  handleSiteVisited,
  handleCancel,
}) => {
  return (
    <>
      <Modal
        open={isModalOpen}
        onCancel={handleCancel}
        width={700}
        footer={null}
      >
        <div className='md:flex md:flex-row lg:mt-10 pt-[50px] pb-[103px]'>
          <div className=''>
            <img src={logo} className='w-[111px]' />
          </div>
          <div className='flex-1 md:px-[30px] mt-[22px] lg:mt-[0px]'>
            <div className='flex flex-row mb-[27px] '>
              <h1 className='flex flex-1 font-Roboto text-mobileH2 font-bold'>
                {title}
              </h1>
              <a
                href={href}
                target='_blank'
                className='underline hover:underline text-[#120051] hover:text-[#120051]'
              >
                Official website
              </a>
            </div>
            <div dangerouslySetInnerHTML={{ __html: content }} className='' />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ModalComponent;
