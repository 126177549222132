import { useState, useEffect, FormEvent, ChangeEvent } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { message } from "antd";

import logo from "../assets/icons/tranzitech-logo.svg";
import ArrowUp from "../assets/icons/arrow-down.svg";
import { scrollToTop } from "../utils/scrollTop";
import instagram from "../assets/icons/instagram.svg";
import twitter from "../assets/icons/twitter.svg";
import linkedIn from "../assets/icons/linkedin.svg";
import Button from "./UiKits/ButtonPrimary";
import { Input } from "./UiKits/Input";
import { isEmail } from "../utils/isEmail";
import { subscribeUser } from "../utils/services/subscriber.service";

const Footer = () => {
  const [isShown, setIsShown] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [subscribe, setSubscribe] = useState<string>("");

  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    const handleScroll = () => {
      const shouldArrowShow = window.scrollY >= 700;
      setIsShown(shouldArrowShow);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (errorMsg) {
      setTimeout(() => {
        setErrorMsg("");
      }, 4000);
    }
  }, [setErrorMsg, errorMsg]);

  const handleSubscribe = async (e: FormEvent) => {
    e.preventDefault();
    if (!isEmail(subscribe)) {
      setErrorMsg("Please enter a valid email address");
    } else {
      setIsLoading(true);
      const subscribeMessage = {
        email: subscribe,
      };
      try {
        const res: any = await subscribeUser(subscribeMessage);
        messageApi.open({
          type: "success",
          content: `${res.data.description}`,
        });
        setSubscribe("");
        setIsLoading(false);
      } catch (error: any) {
        if (error.errorMessageList) {
          setErrorMsg(error.errorMessageList[0]);
          setIsLoading(false);
          return;
        }

        if (error.response.data.errorMessageList) {
          setErrorMsg(error.response.data.errorMessageList[0]);
          setIsLoading(false);
          return;
        }
        if (error.response.data.errors?.Email) {
          setErrorMsg(error.response.data.errors.Email[0]);
          setIsLoading(false);
          return;
        }
      }
    }
  };

  const [socialIcons] = useState([
    { name: "twitter", imgSrc: twitter, link: "https://twitter.com" },
    {
      name: "linkedin",
      imgSrc: linkedIn,
      link: "https://www.linkedin.com/company/tranzitechgroup",
    },
    {
      name: "instagram",
      imgSrc: instagram,
      link: "https://www.instagram.com/tranzitechgroup",
    },
  ]);
  const [pages] = useState([
    { name: "About Us", imgSrc: twitter, link: "/about-us" },
    { name: "Portfolio", imgSrc: linkedIn, link: "/portfolio" },
    { name: "Careers", imgSrc: instagram, link: "/careers" },
    { name: "Media", imgSrc: instagram, link: "/media" },
    { name: "Contact Us", imgSrc: instagram, link: "/contact-us" },
  ]);

  const renderSocialIcons = () =>
    socialIcons.map((icon) => (
      <div className="mx-2" key={icon.name}>
        <div>
          <Link to={icon.link} target="_blank" rel="noopener noreferrer">
            <img src={icon.imgSrc} alt={icon.name} />
          </Link>
        </div>
      </div>
    ));
  const renderLinksToOtherPages = () =>
    pages.map((page) => (
      <div className="mb-3 sm:mb-10" key={page.name}>
        <Link to={page.link}>
          <span className="font-Roboto text-opacity-70 text-sm sm:text-lg">
            {page.name}
          </span>
        </Link>
      </div>
    ));

  return (
    <section className="w-full bg-[#FE7F2D33] h-auto  min-h-[600px] p-5 lg:p-20 relative">
      <div className="absolute"> {contextHolder}</div>
      <div className="flex flex-col lg:flex-row lg:justify-between">
        <div className="flex flex-col">
          <div className="w-5/12 min-w-[100px] mb-4 lg:mb-7">
            <img className="w-full" src={logo} alt="" />
          </div>
          <p className="text-[#06001B] font-Roboto  font-semibold mb-6 sm:mb-[52px] text-sm  sm:text-xl ">
            Propelling Innovation and Growth in Africa
          </p>
          <div className="flex ">
            <p className="text-[#06001B] font-Roboto font-semibold mb-8 sm:mb-[52px] mr-6  text-sm sm:text-xl">
              Follow Us
            </p>
            <div className="flex ">{renderSocialIcons()}</div>
          </div>
        </div>
        <div className="flex flex-col text-[#06001B] mb-6 lg:mb-0">
          {renderLinksToOtherPages()}
        </div>
        <div className="w-full lg:w-[40%]">
          <h2 className="text-[#06001B] text-base lg:text-xl  font-Roboto font-bold mb-3 sm:mb-6">
            Sign Up for Email Updates
          </h2>
          <p className="text-[#06001B] font-Roboto text-opacity-70 text-xs sm:text-base mb-3 sm:mb-6">
            Stay in touch to get exclusive news and insights from across our
            existing portfolio
          </p>
          <div className=" w-full lg:w-[90%] mb-3 lg:mb-5">
            <Input
              type="email"
              name="subscribe"
              placeholder="Email"
              value={subscribe}
              handleChange={(e: ChangeEvent<HTMLInputElement>) =>
                setSubscribe(e.target.value)
              }
            />
            {errorMsg && (
              <p className="text-[#FF0000] text-sm pt-[1%] pl-[1%] italic">
                {errorMsg}
              </p>
            )}
          </div>
          <div
            className="w-full lg:w-[90%] mb-3 lg:mb-10"
            onClick={handleSubscribe}
          >
            <Button
              label="Subscribe"
              btnClass="bg-[#120051]"
              isLoading={isLoading}
            />
          </div>
          <p className="text-[#06001B] font-Roboto  text-xs sm:text-base text-opacity-70">
            By clicking Subscribe, I agree that Tranzitech may use my contact
            details to send me communications and I consent to the Terms as
            provided in the{" "}
            <Link className="font-bold text-[#120051]" to={"/privacy-notice"}>
              {" "}
              Privacy Notice
            </Link>{" "}
            and{" "}
            <Link className="font-bold text-[#120051]" to={"/privacy-policy"}>
              Privacy Policy
            </Link>
            .
          </p>
        </div>
      </div>

      <div className="divider flex justify-center  bg-[#06001B] bg-opacity-20  mt-10 h-[2px]">
        <div className="w-3/5 "></div>
      </div>
      <div className="">
        <div className="pt-6 lg:py-10  w-4/5 flex flex-col lg:flex-row justify-between text-[#06001B] font-Roboto font-semibold ">
          <p className="font-semibold text-sm lg:text-base mb-3 lg:mb-1">
            © {new Date().getFullYear()} Tranzitech. All rights reserved
          </p>
          <p className="text-xs lg:text-base mb-3 lg:mb-1">
            <Link to="/privacy-policy">Privacy Policy</Link>
          </p>
          <p className="text-xs lg:text-base mb-3 lg:mb-1">
            <Link to="/privacy-notice">Privacy Notice</Link>
          </p>
          <p className="text-xs lg:text-base mb-3 lg:mb-1">
            <Link to="/cookie-policy">Cookie Policy</Link>
          </p>
        </div>
      </div>
      {isShown && (
        <div
          className=" fixed z-[100] bottom-[15%] right-[2%] bg-[#00000040] w-[50px] h-[50px] rounded-[50px] p-[1%] cursor-pointer"
          onClick={scrollToTop}
        >
          <img src={ArrowUp} alt="scroll up" />
        </div>
      )}
    </section>
  );
};

export default Footer;
