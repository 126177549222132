import React, { ReactNode } from 'react';
import styles from './aboutSession.module.css';

interface HeaderProps {
  headerText: string;
  firstText: ReactNode;
  secondText: ReactNode;
}

const AboutSession: React.FC<HeaderProps> = ({
  headerText,
  firstText,
  secondText,
}) => {
  return (
    // <div className="w-full">
    <div className='bg-aboutUsSessionBgImage bg-backdropFilter bg-cover bg-no-repeat pt-[203px]'>
      <div className='relative lg:h-[80vh] sm:h-[70vh]'>
        <div className={`flex flex-col lg:pt-16 sm:flex-row pt-5 pb-5`}>
          <div className='flex items-center sm:w-1/2'>
            <div className='flex flex-col'>
              <h2 className='lg:text-H2 sm:text-2xl font-PoppinsNormal text-white ml-4 sm:ml-20 mb-4 sm:mb-0 sm:mt-[30px] lg:mb-[30px] animate__animated animate__bounceInLeft animate__slower'>
                {headerText}
              </h2>
              <p className='text-white ml-4 sm:ml-20 sm:text-base animate__animated animate__bounceInLeft animate__slower'>
                {firstText}
              </p>
            </div>
          </div>
          <div className={`flex items-center lg:justify-center sm:w-1/2`}>
            <h2 className='lg:text-H2 font-PoppinsNormal leading-[125%] text-white sm:text-base  py-5 pl-5 animate__animated animate__bounceInRight animate__slower'>
              {secondText}
            </h2>
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
};

export default AboutSession;
