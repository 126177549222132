import React, { useEffect, useState, useRef } from 'react';
import Slider from 'react-slick';
import {
  lifeAtTranzitech,
  lifeAtTranzitechMobile,
} from '../../../../data/careers';
import LifeAtTranzitechCard from './LifeAtTzCardMobileView';
import LifeAtTranzitechCardNew from './LifeAtTzCardMobileViewNew';

type Cardprops = {
  logo?: any;
  hoverColor?: string;
  hoverText: string;
  gradient: string;
  index?: number;
  title: string;
  gridSpan?: number;
  img: string;
};

const LifeAtTranzitechMobileViewNew = () => {

  const [carouselOptions, setCarouselOptions] = useState({
    dots: false,
    infinite: true,
    speed: 400,
    slidesToShow: 3,
    slidesToScroll: 2,
    autoplay: true,
    autoplaySpeed: 400,
  });

  const firstItemInArray = lifeAtTranzitechMobile.slice(0, 1); //get first item
  const remainingInArray = lifeAtTranzitechMobile.slice(5, 9); //remaining items

  const firstArrayToInterChange = lifeAtTranzitechMobile.slice(1, 3); //item position 1 & 2
  const secondArrayToInterChange = lifeAtTranzitechMobile.slice(3, 5); //item position 3 & 4

  const [firstItem, setFirstItem] = useState<Cardprops[]>(
    firstArrayToInterChange
  );

  const [secondItem, setSecondItem] = useState<Cardprops[]>(
    secondArrayToInterChange
  );

  const lifeAtTranzitechList = [
    ...firstItemInArray,
    firstItem[0],
    secondItem[0],
    ...remainingInArray,
  ];

  const swapItems = (arr1: Cardprops, arr2: Cardprops) => {
    return [arr2, arr1];
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const randomFirstItem = swapItems(firstItem[0], firstItem[1]);
      const randomSecondItem = swapItems(secondItem[0], secondItem[1]);
      setFirstItem(randomFirstItem);
      setSecondItem(randomSecondItem);
    }, 7000);

    return () => clearInterval(interval);
  }, [firstItem, secondItem]);

  return (
      <div
        className='w-full'
      >
          <div className=' grid grid-cols-3   gap-2  '>
            {/* {lifeAtTranzitechList.map((item, index) => (
              <LifeAtTranzitechCardNew item={item} index={index} />
            ))} */}
            <Slider {...carouselOptions}>
            {lifeAtTranzitechList.map((item, index) => (
              <LifeAtTranzitechCardNew item={item} index={index} />
            ))} 
            </Slider>
          </div>

        {/* <img
      src="https://avitechvideos.blob.core.windows.net/tranzitech-website-assets/About%20Us/boris-bobrov-B4lknSRZwPM-unsplash.jpg?sv=2022-11-02&ss=bqtf&srt=sco&sp=rwdlacuptfxiy&se=2023-06-22T19:23:56Z&sig=mNqVRjXPlSBv6UitvATY318eJ2hIgj1vYP6L%2FYtAEG4%3D&_=1687433202758"
      alt=""
    /> */}
      </div>
  );
};

export default LifeAtTranzitechMobileViewNew;
