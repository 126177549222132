import { FC, ChangeEvent } from 'react';
import { Input } from 'antd';

const { TextArea } = Input;

interface ITextAreaProps {
  placeholder: string;
  styles?: {};
  value: string;
  setMessage: any;
}

const TextAreaComponent: FC<ITextAreaProps> = ({
  placeholder,
  styles,
  setMessage,
  value,
}) => {
  const handleTextAreaChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(e.target.value);
  };
  return (
    <>
      <TextArea
        rows={6}
        placeholder={placeholder}
        minLength={2}
        style={styles}
        onChange={handleTextAreaChange}
        value={value}
        className='focus:border-[#120051] focus:ring-[#120051] focus:outline-none resize-none'
      />
    </>
  );
};

export default TextAreaComponent;
