import { FC } from 'react';

import { PictureMedia } from '../../data/media';

type PictureMediaType = {
  id: number;
  imgUrl: string;
  title: string;
};

interface PictureMediaProps {}

const PictureMediaTab: FC<PictureMediaProps> = ({}) => {
  return (
    <div className='lg:flex lg:flex-row lg:space-x-7 space-y-7 lg:space-y-0 mt-[50px] animate__animated animate__slideInLeft'>
      {PictureMedia.map((picture: PictureMediaType) => (
        <div key={picture.id}>
          <img
            src={picture.imgUrl}
            alt={picture.title}
            className='w-[234px] h-[180px] rounded-[12px]'
          />
          <p className='text-sm'>{picture.title}</p>
        </div>
      ))}
    </div>
  );
};

export default PictureMediaTab;
