import React from 'react';

const Hero = () => {
  return (
    <div className='w-full  '>
      <div className='bg-portfolioBgImage   md:bg-cover bg-contain h-auto inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden sm:-top-80   md:h-[100vh] w-full'>
        <div className='p-6 lg:py-20 lg:px-14 flex h-[300px] md:h-full flex-col justify-center   z-20  lg:items-start items-center'>
          <div className='font-PoppinsBold text-white w-full md:w-4/5 h-2/6'>
            <p className='font-PoppinsNormal font-bold mb-3 lg:mb-8 text-lg md:text-left sm:text-4xl lg:text-H1 lg:leading-extraloose   animate__animated animate__zoomIn animate__slower'>
              Revolutionizing Industries Through Innovative Technology Solutions
            </p>
            <p className='font-Roboto hidden  sm:block  text-xs md:text-xl animate__animated animate__slideInLeft animate__slow'>
              Our portfolio companies are the embodiment of our two decades of
              innovation in the <br />
              technology space. From revenue assurance to inventory management,
              we're driving <br /> change across Africa, one solution at a time
            </p>
            <p className='sm:hidden block  text-xs font-light font-Poppins '>
              Our portfolio companies are the embodiment of our two decades of
              innovation in the <br />
              technology space.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
