import React, { useState, useEffect } from 'react';
import Hero from '../../components/Portfolio/Hero';
import CompanyCard from '../../components/Portfolio/Companies';
import { porfolioCompaines } from '../../data/portfolio-icons';
import LogoImage from '../../components/Portfolio/AvitechLogoImage';
import GetInTouch from '../../components/GetInTouch/GetInTouch';
import Footer from '../../components/Footer';

import { PortfolioInfo } from '../../data/portfolio';
import ModalComponent from '../../components/Modal/Modal';
import { scrollToTop } from '../../utils/scrollTop';
// import AvitechCompanyCard from '../../components/Portfolio/Companies';
// import TripsCompanyCard from '../../components/Portfolio/Cards/TripsCompanyCard';

type companyPortfolio = {
  id: string;
  title: string;
  content: string;
  logo: string;
  href: string;
};

const Portfolio = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedContent, setSelectedContent] = useState<companyPortfolio>({
    id: '',
    title: '',
    content: '',
    logo: '',
    href: '',
  });

  useEffect(() => {
    scrollToTop();
  });

  const handleSelectCompany = (item: any) => {
    setSelectedContent({
      id: '',
      title: '',
      content: '',
      logo: '',
      href: '',
    });
    const selectedItem = PortfolioInfo.find(
      (info) => info.id.toLowerCase() === item.name.toLowerCase()
    );
    if (!selectedItem) {
      return;
    }
    setIsModalOpen(true);
    setSelectedContent(selectedItem);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div className=''>
        {' '}
        <Hero />
      </div>
      <div className='grid lg:grid-cols-2 p-8  lg:p-20 w-full  max-w-full  gap-3 lg:justify-center  items-center'>
        {porfolioCompaines.map((company, index) => {
          const CompanyLogo = company.logo;
          return (
            <div
              className=' '
              key={index}
              onClick={() => handleSelectCompany(company)}
            >
              <CompanyCard
                key={index}
                index={index}
                logo={
                  <CompanyLogo
                    className='w-full fill-[#120051] group-hover:fill-white'
                    color=''
                  />
                }
                bgHoverImage={company.bgHoverImage}
                animateType={company.animate}
              />
            </div>
          );
        })}
      </div>
      <div className='flex p-5 justify-center mb-8  md:mb-[120px]'>
        <GetInTouch linkName='Learn More' linkUrl='/about-us' />
      </div>
      <Footer />
      <ModalComponent
        title={selectedContent.title}
        logo={selectedContent.logo}
        href={selectedContent.href}
        content={selectedContent.content}
        isModalOpen={isModalOpen}
        handleCancel={handleCancel}
      />
    </>
  );
};

export default Portfolio;
