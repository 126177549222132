import { FC, useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";

import BlogCard from "../BlogCard/BlogCard";
import FirstBlogCard from "../FirstBlogCard/FirstBlogCard";
import { fetchPosts } from "../../../../utils/services/posts.service";
import { Spin, message } from "antd";
import { Posts } from "../../../../data/homepage-sliders";

type PostType = {
  id: string;
  title: string;
  bannerUrl: string;
  dateCreated: string;
  tags: any;
};

interface BlogGridProps {}

const BlogGrid: FC<BlogGridProps> = ({}) => {
  const navigate = useNavigate();
  const [browserWidth, setBrowserWidth] = useState(window.innerWidth);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [allPosts, setAllPosts] = useState<any[]>([]);
  const [messageApi, contextHolder] = message.useMessage();

  // useEffect(() => {
  //   const handleResize = () => {
  //     setBrowserWidth(window.innerWidth);
  //   };
  //   window.addEventListener("resize", handleResize);

  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, [browserWidth]);

  const fetchPostsData = useCallback(async () => {
    setIsLoading(true);
    const payload: any = {
      pageSize: 4,
      pageNumber: 1,
      parameter: {
        query: "",
      },
    };
    try {
      const res = await fetchPosts(payload);
      setAllPosts(res.data.data.object);
    } catch (error) {
      messageApi.open({
        type: "error",
        content:
          "There was an error while trying to fetch posts, please refresh your browser.",
      });
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    // (async () => await fetchPostsData())();

    (async () => {
      setAllPosts(Posts);
    })();
  }, [Posts, setAllPosts]);

  const handlePostNavigation = (id: string, title: string) => {
    const blogTitle = title.replace(/ /g, "-");
    navigate(`/media/news/${blogTitle}&${id}`);
  };

  // if (!allPosts.length) {
  //   return (
  //     <div className="h-screen flex justify-center items-center">
  //       <Spin />
  //     </div>
  //   );
  // }

  // let allPosts = Posts.filter((post) => post.id !== 1);

  // if (browserWidth < 768) {
  //   setAllPosts(allPosts);
  //   // allPosts = Posts;
  // }

  return (
    <div className="lg:px-[60px] mb-8 md:mb-[120px] px-[10px] mt-[120px] relative">
      <div className="absolute"> {contextHolder}</div>
      <h1 className="md:text-H2 font-PoppinsNormal font-bold text-xl pb-[48px]">
        What’s New
      </h1>
      {isLoading ? (
        <div className="h-screen flex justify-center items-center">
          <Spin />
        </div>
      ) : allPosts.length < 1 ? (
        <div className="h-screen flex justify-center items-center">
          <p>There is active no post</p>
        </div>
      ) : (
        <div className="grid md:grid-cols-2 xl:grid-cols-3 gap-10 justify-between">
          <div
            className="md:col-span-2 animate__animated  animate__slideInLeft"
            onClick={() =>
              handlePostNavigation(allPosts[0].id, allPosts[0].title)
            }
          >
            {browserWidth > 767 && (
              <FirstBlogCard
                title={allPosts[0]?.title}
                blogImg={allPosts[0]?.bannerUrl}
                date={moment(allPosts[0]?.dateCreated).format("LL")}
              />
            )}
          </div>
          {allPosts.slice(1).map((post: PostType) => (
            <div
              key={post.id}
              className="animate__animated  animate__slideInRight"
            >
              <BlogCard
                id={post.id}
                title={post.title}
                blogImg={post.bannerUrl}
                date={moment(post.dateCreated).format("LL")}
                handleSelectedPost={handlePostNavigation}
                tags={post.tags}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default BlogGrid;
