export function getLocalStorageItem(key: string, ttl: number) {
  const storedData = localStorage.getItem(key);
  if (storedData) {
    const { value, timestamp } = JSON.parse(storedData);
    const currentTime = new Date().getTime();
    if (currentTime - timestamp > ttl) {
      localStorage.removeItem(key);
    } else {
      return value;
    }
  }
  return null;
}


export function setLocalStorageItem(){
      const data = {
        value: 'tranzitech-preloader',
        timestamp: new Date().getTime(),
      };
      localStorage.setItem('tranzitech-preloader', JSON.stringify(data));
}
