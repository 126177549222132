import { FC } from 'react';
import Logo from '../../assets/icons/logo.svg';
import { setLocalStorageItem } from '../../utils/cache';

const Preloader: FC = () => {

  setLocalStorageItem();

  return (
    <div className='h-[100vh] w-full bg-[#120051]'>
      <div className='flex items-center justify-center h-screen'>
        <img src={Logo} alt='Tranzitech Holding Logo' />
      </div>
    </div>
  );
};

export default Preloader;
