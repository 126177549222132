import axios from "axios";
import { BASE_API_URL } from "../constant";

export const subscribeUser = async (payload: any) => {
  const data = await axios.post(
    `${BASE_API_URL}/api/Subscription/Subscribe`,
    payload
  );

  return data;
};

export const sendMessage = async (payload: any) => {
  const data = await axios.post(
    `${BASE_API_URL}/api/Contact/SendContact`,
    payload
  );
  return data;
};
