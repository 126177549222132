import { useState, ChangeEvent, FormEvent } from 'react';
import { Spin } from 'antd';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

import { Input } from '../UiKits/Input';
import Button from '../UiKits/ButtonPrimary';
import TextAreaComponent from '../UiKits/TextArea';
import { isEmail } from '../../utils/isEmail';
import Success from '../../pages/contact/Success';
import { sendMessage } from '../../utils/services/subscriber.service';

type UserInputType = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  subject: string;
};

interface ErrorType {
  data: {
    errors: {};
  };
}

const Form = () => {
  const [isValid, setIsValid] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [isSuccessful, setIsSuccessful] = useState<boolean>(false);
  const [userMessage, setUserMessage] = useState('');
  const [errorMsg, setErrorMsg] = useState<string[]>([]);
  const [userInput, setUserInput] = useState<UserInputType>({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    subject: '',
  });

  const onChangePhoneHandler = (value: string, data: any, event: React.ChangeEvent<HTMLInputElement>, formattedValue: string) => {
    setUserInput({
      ...userInput,
      phoneNumber: value || '',
    });
  };

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUserInput((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const onSubmitHandler = async (e: FormEvent) => {
    e.preventDefault();
    if (
      !userInput.firstName ||
      !userInput.lastName ||
      !userInput.email ||
      !userInput.subject ||
      !userInput.phoneNumber ||
      isNaN(+userInput.phoneNumber) ||
      !userMessage ||
      !isChecked
    ) {
      setIsValid(false);
      return;
    } else {
      if (!isEmail(userInput.email)) {
        setIsValid(false);
        return;
      }
      setIsLoading(true);
      setIsValid(true);
      const messagePayload = {
        firstName: userInput.firstName,
        lastName: userInput.lastName,
        email: userInput.email,
        phoneNumber: userInput.phoneNumber,
        subject: userInput.subject,
        message: userMessage,
      };
      try {
        await sendMessage(messagePayload);
        setIsSuccessful(true);
        setUserInput({
          firstName: '',
          lastName: '',
          email: '',
          phoneNumber: '',
          subject: '',
        });
        setUserMessage('');
        setErrorMsg([]);
        setIsLoading(false);
      } catch (error: any | ErrorType) {
        setIsSuccessful(false);
        setIsValid(false);
        const resError = error.response.data.errors;
        if (resError.Email) {
          setErrorMsg(resError.Email);
          setIsLoading(false);
          return;
        }

        if (resError.Message) {
          setErrorMsg(resError.Message);
          setIsLoading(false);
          return;
        }

        if (resError.FirstName) {
          setErrorMsg(resError.FirstName);
          setIsLoading(false);
          return;
        }

        if (resError.PhoneNumber) {
          setErrorMsg(resError.PhoneNumber);
          setIsLoading(false);
          return;
        }
      }
    }
  };

  if (isLoading) {
    return (
      <div className='h-full flex items-center justify-center h-screen'>
        <Spin />
      </div>
    );
  }

  if (isSuccessful) {
    setTimeout(() => {
      setIsSuccessful(false);
      setIsChecked(false);
    }, 6000);
  }
  return !isSuccessful ? (
    <form
      onSubmit={onSubmitHandler}
      className='space-y-4 lg:bg-[#06001b0d] lg:border lg:border-solid lg:border-[#120051] rounded-[24px] lg:py-[50px] lg:px-[48px] my-[120px]'
    >
      {errorMsg && (
        <p className='text-[#FF0000] text-sm pt-[1%] pl-[1%] italic'>
          {errorMsg[0]}
        </p>
      )}
      <div className='lg:flex lg:flex-row space-y-4 lg:space-y-0 lg:space-x-4'>
        <div className='w-full'>
          <Input
            placeholder='First Name'
            value={userInput.firstName}
            name='firstName'
            handleChange={onChangeHandler}
          />
          {!isValid && !userInput.firstName ? (
            <p className='text-[#FF0000] text-sm pt-[1%] pl-[1%] italic'>
              First Name is required
            </p>
          ) : (
            ''
          )}
        </div>
        <div className='w-full'>
          <Input
            placeholder='Last Name'
            value={userInput.lastName}
            name='lastName'
            handleChange={onChangeHandler}
          />
          {!isValid && !userInput.lastName ? (
            <p className='text-[#FF0000] text-sm pt-[1%] pl-[1%] italic'>
              Last Name is required
            </p>
          ) : (
            ''
          )}
        </div>
      </div>
      <div className='lg:flex lg:flex-row space-y-4 lg:space-y-0 lg:space-x-4'>
        <div className='w-full'>
          <Input
            placeholder='Email'
            name='email'
            type='text'
            value={userInput.email}
            handleChange={onChangeHandler}
          />
          {(!isValid && !userInput.email) ||
          (!isValid && !isEmail(userInput.email)) ? (
            <p className='text-[#FF0000] text-sm pt-[1%] pl-[1%] italic'>
              Email Address is required and must be valid
            </p>
          ) : (
            ''
          )}
        </div>
        {/* <div className='w-full'>
          <Input
            placeholder='Phone Number'
            name='phoneNumber'
            value={userInput.phoneNumber}
            handleChange={onChangeHandler}
          />
          {(!isValid && !userInput.phoneNumber) ||
          (!isValid && isNaN(+userInput.phoneNumber)) ? (
            <p className='text-[#FF0000] text-sm pt-[1%] pl-[1%] italic'>
              Phone Number is required and must be numbers
            </p>
          ) : (
            ''
          )}
        </div> */}
        <PhoneInput
          country={'us'}
          value={userInput.phoneNumber}
          onChange={onChangePhoneHandler}
        />
      </div>
      <div>
        <Input
          placeholder='Subject'
          name='subject'
          value={userInput.subject}
          handleChange={onChangeHandler}
        />
        {!isValid && !userInput.subject ? (
          <p className='text-[#FF0000] text-sm pt-[1%] pl-[1%] italic'>
            Subject is required
          </p>
        ) : (
          ''
        )}
      </div>
      <div>
        <TextAreaComponent
          placeholder='Message'
          value={userMessage}
          setMessage={setUserMessage}
          styles={{
            height: 240,
            borderColor: '#06001B',
            borderRadius: 12,
            color: '#06001B',
            fontFamily: 'Roboto',
            background: 'transparent',
          }}
        />
        {!isValid && !userMessage ? (
          <p className='text-[#FF0000] text-sm pt-[1%] pl-[1%] italic'>
            Message is required
          </p>
        ) : (
          ''
        )}
      </div>

      <div className='flex flex-row'>
        <div className='pr-[2%]'>
          <input
            type='checkbox'
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setIsChecked(e.target.checked)
            }
            checked={isChecked}
          />
        </div>
        <p className='text-sm text-justify font-Roboto text-[#120051]'>
          By clicking “Submit”, I agree that Tranzitech may use my contact
          details to send me communications and I consent to the Terms as
          provided in the{' '}
          <span className='font-RobotoBold cursor-pointer'>
            <a href='/privacy-notice' target='_blank'>
              Privacy Notice
            </a>
          </span>{' '}
          and{' '}
          <span className='font-RobotoBold cursor-pointer'>
            <a href='/privacy-policy' target='_blank'>
              Privacy Policy
            </a>
          </span>
          .
        </p>
      </div>

      <Button
        label='Submit'
        isLoading={!isChecked}
        btnClass={`${
          !isChecked
            ? 'bg-[#000] pointer-events-none opacity-50'
            : 'bg-[#120051]'
        }`}
      />
    </form>
  ) : (
    <Success />
  );
};

export default Form;
