import { FC } from 'react';
import { Empty } from 'antd';

interface VideoMediaTabProps {}

const VideoMediaTab: FC<VideoMediaTabProps> = () => {
  return (
    <div>
      <Empty />
    </div>
  );
};

export default VideoMediaTab;
