// import cardImg1 from '../../src/assets/images/AboutSession.jpg';
// import AboutHero from '../assets/images/AboutUsHero.jpg'

export const tabContent = [
  {
    list: [
      'Challenge the status quo and find new ways to win the market',
      'Create a bold and inspiring direction',
      'Proactively solve problems; don’t just complain',
      'Coach and develop new leaders',
    ],
    img: 'https://avitechvideos.blob.core.windows.net/tranzitech-website-assets/Careers/shutterstock_1567573876-2.jpg',
  },
  {
    list: [
      'Keep communication simple – no vagueness or hiding of details',
      'Be timely with your communication at all levels',
      'Listen carefully to understand and seek out or give feedback',
      'Speak up in front of people and not behind them with candor',
    ],
    img:'https://avitechvideos.blob.core.windows.net/tranzitech-website-assets/Careers/shutterstock_2151104711-1.jpg'
  },
  {
    list: [
      'Attach due date and an owner to every task/goal',
      'Empower yourself and others to make decisions, and commit to Group decisions',
      'Execute actions and deliver to high standards on time',
      'Reward excellence and apply consequences accordingly',
    ],
    img: 'https://avitechvideos.blob.core.windows.net/tranzitech-website-assets/Careers/shutterstock_1744993268.jpg',
  },
  {
    list: [
      'Deeply seek to understand yourself, your values and character',
      'Be comfortable talking about your growth areas and confident in your strengths',
      'Express your feelings with candor and don’t take feedback personally',
      'Choose kindness always and show empathy to everyone',
    ],
    img: 'https://avitechvideos.blob.core.windows.net/tranzitech-website-assets/Careers/shutterstock_2104497341-1.jpg',
  },
  {
    list: [
      'Put aside your title and work side-by-side with everyone',
      'Be open, about what you do not know, and curious to learn from everyone',
      'Encourage vulnerability by supporting and caring for everyone',
      'Own up to your mistakes or failures, learn and improve',
    ],
    img: 'https://avitechvideos.blob.core.windows.net/tranzitech-website-assets/Careers/shutterstock_1929731783-1.jpg',
  },
  {
    list: [
      'Believe in and value every team member',
      'Share knowledge and genuinely work with others to succeed',
      'Always look out for opportunities to collectively succeed',
      'Celebrate diversity of thoughts and reward team results',
    ],
    img: 'https://avitechvideos.blob.core.windows.net/tranzitech-website-assets/Careers/shutterstock_1272443692-1.jpg',
  },
];
