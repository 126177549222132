import { FC, useState } from 'react';
import Slider from 'react-slick';


import Button from '../UiKits/Button';
import arrow from '../../assets/icons/arrow-up.svg';

import styles from './Carousel.module.css';
import '../../styles/utility/ _carousel.css';

interface CarouselProps {
  carouselData?: carouselType[];
  onClickBtn: () => boolean | any;
  scrollDown: () => {} | void;
}

type carouselType = {
  id: number;
  img: string;
  content: string;
  buttonText: string;
  showBtn: boolean;
};

const Carousel: FC<CarouselProps> = ({
  carouselData = [],
  onClickBtn,
  scrollDown,
}) => {
  const [carouselOptions, setCarouselOptions] = useState({
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  });

  return (
    <div className='lg:h-screen relative'>
      <Slider {...carouselOptions}>
        {carouselData.map((item) => {
          return (
            <div key={`Carousel${item.id}`} className='h-[1024px] relative'>
              <div className='absolute bg-black bg-opacity-60 w-[100%] h-[100%] animate__animated animate__slideInDown '></div>
              <img src={item.img} alt='' className='object-cover ' />
              <div
                className={`absolute h-[100%] flex flex-column justify-center ${styles.carouselContainer}`}
                onClick={scrollDown}
              >
                <p
                  className={`sp-text-color--white font-PoppinsBold text-sm text-base lg:mt-[-20%] ${styles.carouselContainer} sm:text-mobileH1 text-H1 animate__slideInLeft`}
                >
                  {item.content}
                </p>
                {item.showBtn && (
                  <div
                    className={`flex flex-row bg-black bg-opacity-25 px-3 py-4 w-12 absolute right-[8%] ${styles.scrollDownContainer}`}
                    onClick={onClickBtn}
                  >
                    <Button
                      label='Scroll down'
                      className='text-white pr-3'
                      onClick={onClickBtn}
                    />
                    <img
                      src={arrow}
                      alt='scroll down'
                      className={styles.scrollDownIcon}
                    />
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

export default Carousel;
