import { FC } from 'react';

interface PortfolioCardProps {
  imgUrl: string;
}

const PortfolioCard: FC<PortfolioCardProps> = ({ imgUrl }) => {
  return (
    <div className='bg-white h-[150px] w-[300px] lg:w-[100%] lg:h-[360px] px-[80px] py-[49px] lg:px-[87px] lg:py-[165px] lg:mb-[32px] rounded-[12px]'>
      <img src={imgUrl} alt='logo' />
    </div>
  );
};

export default PortfolioCard;
