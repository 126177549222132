import { FC, useState } from 'react';
import faQouteUp from '../../../assets/icons/fa_quote-up.svg';
import faQouteDown from '../../../assets/icons/fa_quote-down.svg';

// import '../../Slider/Slider.css';

type SliderType = {
  id: number;
  name: string;
  title: string;
  imgUrl: string;
  companyImgUrl: string;
  about: string;
};

interface SliderCarouselProps {
  slides: SliderType[];
  activeSlide: number;
}

const SliderCarousel: FC<SliderCarouselProps> = ({ slides, activeSlide }) => {
  return (
    <div className='slider-container w-full h-full bg-white shadow-light-shadow  lg:mb-[1%] rounded-[12px]'>
      <div
        className='slider-wrapper lg:flex lg:flex-row  w-full'
        style={{ transform: `translateX(-${activeSlide * 100}%)` }}
      >
        {slides.map((slide, index) => (
          <div
            key={slide.id}
            className={`lg:flex lg:flex-row bg-white lg:w-[100%] lg:flex-shrink-0  frounded slide ${
              index === activeSlide ? 'active' : ''
            } ${
              index === activeSlide - 1 ||
              (activeSlide === 0 && index === slides.length - 1)
                ? ''
                : ''
            } ${
              index === activeSlide + 1 ||
              (activeSlide === slides.length - 1 && index === 0)
                ? ''
                : ''
            }`}
          >
            <div className=' bg-[#1200510c] relative  h-96 bg-opacity-5 w-full lg:w-[50%] m rounded-bl-[10px]  flex justify-center items-center'>
              <img
                src={slide.imgUrl}
                className='w-56 object-contain bottom-0 absolute'
                alt=''
              />
            </div>

            <div className='  p-5 flex flex-col relative  justify-center items-center text-left lg:w-[50%] border-2 border-slate-100 '>
              <img
                src={faQouteUp}
                alt=''
                className='w-20 absolute top-20 left-8'
              />
              <div className='lg:w-[75%]  mx-auto'>
                <p className='text-base '>{slide.about}</p>
                <div className=' flex gap-3 items-center mt-7'>
                  {/* <img className='border-2 border-[#120051] rounded-full w-16 h-16' src={slide.imgUrl} alt="" /> */}
                  <div>
                    <h1 className='text-lg font-Roboto'>{slide.name}</h1>
                    <small className='text-xs text-[#06001bcc]'>
                      {slide.title}
                    </small>
                  </div>
                </div>
                <img
                  src={faQouteDown}
                  alt=''
                  className='w-20 absolute bottom-32 right-16'
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SliderCarousel;
