import React, {  } from 'react';

type Iprops = {
  logo: any;
  bgHoverImage?: string;
  index?: number;
  handleClick?: () => void
  animateType?:  string
};

const AvitechCompanyCard: React.FC<Iprops> = ({ logo, bgHoverImage, handleClick, animateType='backInLeft' }) => {
    bgHoverImage = bgHoverImage?.trim()
    const img = bgHoverImage === 'bg-avitechHoverBgImage'? 'after:bg-avitechHoverBgImage': bgHoverImage === 'bg-tripsHoverBgImage'? 'after:bg-tripsHoverBgImage':''
    const imgHover = bgHoverImage === 'bg-avitechHoverBgImage'? 'after:hover:bg-avitechHoverBgImage': bgHoverImage === 'bg-tripsHoverBgImage'? 'after:hover:bg-tripsHoverBgImage':''
    const animate = "animate__" + animateType?.trim()
  return (
    <div
      className={`group hover:bg-[#06001B0D]   animate__animated ${animate} rounded-lg  z-10  w-full max-w-full bg-[#06001B0D] h-40 md:h-96 flex justify-center items-center
    after:top-full  ${img} after:hover:bg-opacity-40 after:h-0 after:z-0  after:ease-in  after:hover:top-0 after:transition-all after:hover:duration-1000 after:hover:ease-in-out
     ${imgHover} after:hover:bg-cover  after:hover:w-full  lg:after:hover:h-96  after:hover:h-40
    after:hover:absolute after:hover:animate__slideInUp relative `}
    >
      {/* <div className=" after:absolute top-full after:hover:top-0 after:hover:transition-all  bg-portfolioBgImage  bg-cover w-full h-full"> */}

      <div className='w-2/5 z-50'>
        {logo}
      </div>
      {/* </div> */}
    </div>
  );
};

export default AvitechCompanyCard;
