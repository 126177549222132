import React from 'react';

const CareerHero = () => {
  return (
    <div className='w-full'>
      <div className='bg-careerBgImage  bg-cover  bg-no-repeat h-[50vh] sm:h-[70vh] lg:h-[100vh] w-full'>
        <div className='p-6 lg:p-20 flex h-[300px] md:h-full flex-col justify-center lg:items-start items-center'>
          <div className='font-PoppinsBold text-white w-full md:w-4/5 h-2/6'>
            <p className='font-Poppins font-semibold mb-3 lg:mb-8  lg:text-7xl pt-[50px] lg:pt-[0px] text-[24px] text-H1 animate__animated animate__zoomIn animate__slower'>
              Discover Your Next Career Adventure
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CareerHero;
